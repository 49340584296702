import { useStyles } from "../../styles/Style.index";
import MainLayout from "../../components/MainLayout";
import {
  Shape,
  Shape2,
  Shape3,
  Shape4,
  Shape5,
  Shape6,
  Shape7,
  Shape8,
  Shape9,
  Shape10,
  Shape11,
  Shape12,
  Shape13,
  Shape14,
  Shape15,
  Shape16,
  Shape17,
  Shape18,
  Shape19,
  Shape20,
} from "../../components/Svg/shape";
import { GreenShape } from "../../components/Svg/greenShape";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  Collapse,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Search,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  InfoOutlined,
} from "@material-ui/icons";
import { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { validate } from "indicative/validator";
import Modal from "react-modal";
import { BsX, BsCheckCircle } from "react-icons/bs";
import { ApiPost, ApiGet, ApiGetAuth } from "../api/api";
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from "react-transition-group";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SameZoneModal from "../../components/SameZoneModal";

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [countryActive, setCountryActive] = useState(true);

  const under750 = useMediaQuery("(max-width: 750px)");

  const reduxPlans = useSelector((state: any) => state.plans);
  const reduxBlogs = useSelector((state: any) => state.blogs);
  const reduxCountries = useSelector((state: any) => state.countries);
  const reduxZones = useSelector((state: any) => state.zones);
  const userData = useSelector((state: any) => state.userData);

  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth > 0 ? window.innerWidth : screen.width);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth > 0 ? window.innerWidth : screen.width);
    });
  }, []);

  const [plans, setPlans] = useState([] as any);
  const [popularCountries, setPopularCountries] = useState([] as any);
  const [countries, setCountries] = useState([] as any);
  const [zones, setZones] = useState([] as any);
  const [bundlesLoading, setBundlesLoading] = useState(true);

  const [blogPosts, setBlogPosts] = useState(null as any);

  const [introPurchased, setIntroPurchased] = useState(false);
  const [purchasedBundles, setPurchasedBundles] = useState([] as any);

  useEffect(() => {
    let isMounted = true;

    if (reduxCountries) {
      setPopularCountries(reduxCountries.data.popularCountries);
      setCountries(reduxCountries.data.countries);
      setBundlesLoading(false);
    } else {
      ApiGet("/plans/countries")
        .then((response: any) => {
          if (isMounted) {
            dispatch({ type: "set", countries: response });
            setPopularCountries(response.data.popularCountries);
            setCountries(response.data.countries);
            setBundlesLoading(false);
          }
        })
        .catch((error: any) => {});
    }

    /*if (reduxPlans) {
      setPlans(reduxPlans.data.plans);
      setBundlesLoading(false);
    } else {
      ApiGet("/plans")
        .then((response: any) => {
          if (isMounted) {
            dispatch({ type: "set", plans: response });
            setPlans(response.data.plans);
            setBundlesLoading(false);
          }
        })
        .catch((error: any) => {});
    }*/

    if (reduxZones) {
      setZones(reduxZones.data.zones);
    } else {
      ApiGet("/plans/zones")
        .then((response: any) => {
          if (isMounted) {
            dispatch({ type: "set", zones: response });
            setZones(response.data.zones);
          }
        })
        .catch((error: any) => {});
    }

    if (reduxBlogs) {
      setBlogPosts(reduxBlogs.data["blog posts"]);
    } else {
      ApiGet("/blog")
        .then((response: any) => {
          if (isMounted) {
            dispatch({ type: "set", blogs: response });
            setBlogPosts(response.data["blog posts"]);
          }
        })
        .catch((error: any) => {});
    }

    if (userData) {
      setIntroPurchased(userData.data.introPlanPurchased);
      setPurchasedBundles(userData.data.subscriptions);
    } else {
      ApiGetAuth("/users")
        .then((response: any) => {
          if (isMounted) {
            dispatch({ type: "set", userData: response });
            setIntroPurchased(response.data.introPlanPurchased);
            setPurchasedBundles(response.data.subscriptions);
          }
        })
        .catch((error) => {});
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [tellMeMore, setTellMeMore] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [tellMeMoreDisabled, setTellMeMoreDisabled] = useState(true);
  const [tellMeMoreLoading, setTellMeMoreLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    checkInputs();
  }, [tellMeMore, checked]);

  const handleInput = (event: any, prop: string) => {
    setTellMeMore({
      ...tellMeMore,
      [prop]: event.target.value,
    });
  };

  const checkInputs = () => {
    const rules = {
      email: "required|email",
    };
    const data = {
      email: tellMeMore.email,
    };
    validate(data, rules)
      .then((response) => {
        if (tellMeMore.firstName && tellMeMore.lastName && checked) {
          setTellMeMoreDisabled(false);
        } else {
          setTellMeMoreDisabled(true);
        }
      })
      .catch((error) => {
        0;
        setTellMeMoreDisabled(true);
      });
  };

  //////////////////////////////////////////////////////////////////////////
  /****              THANK YOU MODAL                                *******/

  const modalStyles: Modal.Styles =
    width > 650
      ? {
          content: {
            top: "50%",
            left: "50%",
            right: "50%",
            bottom: "auto",
            width: "600px",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#20ce88",
            boxShadow: "rgba(0, 0, 0, 1) 0px 5px 15px",
            padding: 0,
            border: "none",
            borderRadius: "15px",
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }
      : {
          content: {
            top: "50%",
            left: "50%",
            right: "50%",
            bottom: "auto",
            width: "90%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#20ce88",
            boxShadow: "rgba(0, 0, 0, 1) 0px 5px 15px",
            padding: "0 15px",
            border: "none",
            borderRadius: "15px",
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  //////////////////////////////////////////////////////////////////////////
  /****                  VIDEO MODAL                                *******/

  const videoModalStyles: Modal.Styles =
    width > 950
      ? {
          content: {
            top: "50%",
            left: "50%",
            right: "50%",
            bottom: "auto",
            width: "calc(1.777777777 * 70vh)",
            height: "70vh",
            transform: "translate(-50%, -50%)",
            background: "none",
            boxShadow: "rgba(0, 0, 0, 1) 0px 5px 15px",
            padding: 0,
            border: "none",
            borderRadius: "15px",
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }
      : {
          content: {
            top: "50%",
            left: "50%",
            right: "50%",
            bottom: "auto",
            width: "90%",
            height: "auto",
            transform: "translate(-50%, -50%)",
            background: "none",
            boxShadow: "rgba(0, 0, 0, 1) 0px 5px 15px",
            padding: 0,
            border: "none",
            borderRadius: "15px",
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        };

  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);

  const openVideoModal = () => {
    setVideoModalIsOpen(true);
  };

  const closeVideoModal = () => {
    setVideoModalIsOpen(false);
  };

  const handleOpenVideo = () => {
    openVideoModal();
  };

  const [registerError, setRegisterError] = useState(false);

  const closeRegisterError = () => {
    setRegisterError(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  /***************             BUNDLE SECTION                       ***************************/

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([] as any);
  const [bundlesTitle, setBundlesTitle] = useState("Popular Countries");
  const [showAll, setShowAll] = useState(false);

  const showAllCountries = () => {
    setSearchQuery("");
    setShowAll(!showAll);
  };

  const handleSearchChange = (event: any) => {
    setShowAll(false);
    setCountryActive(true);
    if (event.target.value) {
      setBundlesTitle("Country Search");
    } else {
      setBundlesTitle("Popular Countries");
    }

    let newArr = countries.filter((country: any) =>
      country.countryName
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setFilteredCountries(newArr);
    setSearchQuery(event.target.value);
  };

  const [activeCurrency, setActiveCurrency] = useState({
    currencyCode: "GBP",
    symbol: "£",
  });

  useEffect(() => {
    const preferredCurrency = localStorage.getItem("preferredCurrency");
    if (preferredCurrency) {
      let formatted = {
        target: {
          value: preferredCurrency,
        },
      };
      handleCurrencyChange(formatted);
    }
  }, []);

  const handleCurrencyChange = (event: any) => {
    switch (event.target.value) {
      case "GBP":
        setActiveCurrency({
          currencyCode: "GBP",
          symbol: "£",
        });
        break;
      case "USD":
        setActiveCurrency({
          currencyCode: "USD",
          symbol: "$",
        });
        break;
      case "EUR":
        setActiveCurrency({
          currencyCode: "EUR",
          symbol: "€",
        });
        break;
    }
  };

  const [zonalError, setZonalError] = useState({
    show: false,
    continue: null,
  });

  const [activePhoneImage, setActivePhoneImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActivePhoneImage((prevImage) => (prevImage + 4) % 3);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <MainLayout>
      <Snackbar
        open={registerError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={closeRegisterError}
      >
        <Alert
          severity="error"
          onClose={closeRegisterError}
          style={{
            color: "#fff",
            backgroundColor: "#e74c3c",
            fontSize: "16px",
          }}
        >
          There was a problem registering your email, please try again.
        </Alert>
      </Snackbar>

      <SameZoneModal show={zonalError} setShow={setZonalError} />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <button className={classes.modalClose} onClick={closeModal}>
          <BsX />
        </button>
        <div className={classes.thankYouTitle}>
          <BsCheckCircle />
          <h1>Thanks for registering!</h1>
        </div>
        <div className={classes.thankYouText}>
          As soon as BreatheSIM is available, we'll be sure to let you know!
        </div>
      </Modal>

      <Modal
        isOpen={videoModalIsOpen}
        onRequestClose={closeVideoModal}
        style={videoModalStyles}
      >
        <button className={classes.modalClose} onClick={closeVideoModal}>
          <BsX />
        </button>
        <video
          autoPlay
          width="100%"
          height="auto"
          controls
          style={{ borderRadius: "15px", border: "none" }}
        >
          <source src="/BreatheSIM_Timeline_Pound_v13.mp4"></source>
          Your browser does not support the video tag.
        </video>
      </Modal>

      <section className={classes.titleSection}>
        {width > 1770 ? (
          <Shape />
        ) : width > 1520 ? (
          <Shape2 />
        ) : width > 1300 ? (
          <Shape3 />
        ) : width > 1150 ? (
          <Shape4 />
        ) : width > 960 ? (
          <Shape6 />
        ) : width > 910 ? (
          <Shape8 />
        ) : width > 820 ? (
          <Shape9 />
        ) : width > 705 ? (
          <Shape10 />
        ) : width > 630 ? (
          <Shape11 />
        ) : width > 580 ? (
          <Shape12 />
        ) : width > 530 ? (
          <Shape13 />
        ) : width > 460 ? (
          <Shape14 />
        ) : width > 400 ? (
          <Shape15 />
        ) : width > 360 ? (
          <Shape16 />
        ) : width > 325 ? (
          <Shape17 />
        ) : (
          <Shape18 />
        )}
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} md={5} className={classes.phoneContainer}>
            <div className={classes.phoneAnimationContainer}>
              <img
                src="/iPhone Image.png"
                className={classes.phone}
                alt="mobile phone mockup"
              />
              <div className={classes.phoneCarousel}>
                <img
                  src="/Screen 1 Mask.png"
                  alt="select destiniation app screen"
                  className={
                    activePhoneImage === 1
                      ? classes.phoneActive
                      : classes.phoneInactive
                  }
                />
                <img
                  src="/Screen 2 Mask.png"
                  alt="select plan app screen"
                  className={
                    activePhoneImage === 2
                      ? classes.phoneActive
                      : classes.phoneInactive
                  }
                />
                <img
                  src="/Screen 3 Mask.png"
                  alt="success app screen"
                  className={
                    activePhoneImage === 0
                      ? classes.phoneActive
                      : classes.phoneInactive
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className={classes.titleText}>
              <h1 className={classes.heading}>Set your data free.</h1>
              <h2 className={classes.tagLine}>
                Discover a whole new world of low-cost, super-fast, hassle-free
                data when travelling.
              </h2>
              {/*<button className='badge'><img src='app-store.svg' alt='app store badge' className={classes.appStore} /></button>
                            <button className='badge'><img src='google-play-badge.png' alt='google play badge' className={classes.googlePlay} /></button>*/}
              <div className={classes.smallPhoneContainer}>
                <img src="/iPhone Image.png" className={classes.smallPhone} />
                <div className={classes.smallPhoneCarousel}>
                  <img
                    src="/Screen 1 Mask.png"
                    alt="select destiniation app screen"
                    className={
                      activePhoneImage === 1
                        ? classes.phoneActive
                        : classes.phoneInactive
                    }
                  />
                  <img
                    src="/Screen 2 Mask.png"
                    alt="select plan app screen"
                    className={
                      activePhoneImage === 2
                        ? classes.phoneActive
                        : classes.phoneInactive
                    }
                  />
                  <img
                    src="/Screen 3 Mask.png"
                    alt="success app screen"
                    className={
                      activePhoneImage === 0
                        ? classes.phoneActive
                        : classes.phoneInactive
                    }
                  />
                </div>
              </div>

              <Box className={classes.appContainer}>
                {under750 ? (
                  <a
                    className={classes.appStore}
                    href="https://apps.apple.com/gb/app/breathesim-esim-travel-data/id1550961190?mt=8"
                  >
                    <img src="/app-store.svg" alt="app store badge" />
                  </a>
                ) : (
                  <a
                    className={classes.appStore}
                    href="https://apps.apple.com/gb/app/breathesim-esim-travel-data/id1550961190"
                    target="_blank"
                  >
                    <img src="/app-store.svg" alt="app store badge" />
                  </a>
                )}
                <a
                  className={classes.googlePlay}
                  href="https://play.google.com/store/apps/details?id=com.manxtelecom.breathesim&gl=GB"
                  target="_blank"
                >
                  <img src="/google-play-badge.png" alt="google play badge" />
                </a>
              </Box>
              <button
                className={classes.seeHowItWorks}
                onClick={handleOpenVideo}
              >
                See how it works
              </button>
            </div>
          </Grid>
        </Grid>
      </section>

      {
        ///////////////////////////////////////////////////////////////////
        //*********************  STEPS SECTION **************************//
      }

      <section className={classes.stepsSection}>
        <div style={{ position: "relative", zIndex: 1 }}>
          <h1 className={classes.stepsHeading}>Breathe easy when travelling</h1>
          <h2 className={classes.stepsTag}>
            Discover low-cost, hassle free data, wherever you roam.
          </h2>
        </div>
        <div className={classes.imgContainer}>
          <div>
            <img
              src="/download.svg"
              style={{
                height: "170px",
                width: "119.73",
                marginLeft: "25px",
                position: "relative",
                bottom: 15,
              }}
              alt="hand holding a mobile icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>1</div>
              <p className={classes.step}>Download the app</p>
            </div>
          </div>
          <div>
            <img
              src="/earth.svg"
              style={{ marginBottom: "37px" }}
              alt="earth icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>2</div>
              <p className={classes.step}>Select your destination</p>
            </div>
          </div>
          <div>
            <img
              src="/wallet.svg"
              style={{ marginBottom: "37px" }}
              alt="wallet icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>3</div>
              <p className={classes.step}>Choose your bundle</p>
            </div>
          </div>
          <div>
            <img
              src="/balloon.svg"
              style={{ marginBottom: "37px" }}
              alt="hot air balloon icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>4</div>
              <p className={classes.step}>You're good to go</p>
            </div>
          </div>
        </div>
        <Carousel
          className={classes.carousel}
          autoPlay={true}
          indicators={true}
          indicatorIconButtonProps={{
            style: {
              margin: "0 2px",
              padding: "10px",
              color: "#fff",
              height: "15px",
              width: "15px",
              transition: "all 0.5s ease",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#175458",
            },
          }}
          indicatorContainerProps={{
            style: {
              position: "absolute",
              bottom: 5,
            },
          }}
        >
          <div style={{ width: "100%" }}>
            <img
              src="/download.svg"
              style={{
                height: "170px",
                padding: "0 50px",
                marginBottom: "13px",
              }}
              alt="hand holding a mobile icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>1</div>
              <p className={classes.step}>Download the app</p>
            </div>
          </div>
          <div>
            <img
              src="/earth.svg"
              style={{ marginBottom: "50px" }}
              alt="earth icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>2</div>
              <p className={classes.step}>Select your destination</p>
            </div>
          </div>
          <div>
            <img
              src="/wallet.svg"
              style={{ marginBottom: "50px" }}
              alt="wallet icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>3</div>
              <p className={classes.step}>Choose your bundle</p>
            </div>
          </div>
          <div>
            <img
              src="/balloon.svg"
              style={{ marginBottom: "50px" }}
              alt="hot air balloon icon"
            />
            <div className={classes.stepContainer}>
              <div className={classes.number}>4</div>
              <p className={classes.step}>You're good to go</p>
            </div>
          </div>
        </Carousel>
      </section>

      {
        ///////////////////////////////////////////////////////////////////
        //*********************  BUNDLE SECTION *************************//
      }

      <section className={classes.bundleSection} id="shop">
        <h1 className={classes.bundleHeading}>Choose your data bundle</h1>
        <div className={classes.searchBar}>
          <Search />
          <input
            className={classes.searchInput}
            type="text"
            placeholder="Search data bundles for 70+ countries worldwide"
            onChange={(event) => {
              handleSearchChange(event);
            }}
            value={searchQuery}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className={
              countryActive
                ? classes.countryZoneBtnActive
                : classes.countryZoneBtn
            }
            onClick={() => {
              if (!countryActive) {
                setCountryActive(true);
                setBundlesTitle(
                  searchQuery ? "Country Search" : "Popular Countries"
                );
              }
            }}
          >
            By Country
          </button>
          <button
            className={
              !countryActive
                ? classes.countryZoneBtnActive
                : classes.countryZoneBtn
            }
            onClick={() => {
              if (countryActive) {
                setCountryActive(false);
                setBundlesTitle("Zones");
              }
            }}
          >
            By Zone
          </button>
        </div>
        <h2 className={classes.popularHeading}>
          {!countryActive
            ? "Zones"
            : searchQuery
            ? "Country search"
            : showAll
            ? "All Countries"
            : "Popular Countries"}
        </h2>
        <div
          style={{
            display: "flex",
            width: "100%",
            margin: "30px 0",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className={classes.currencyHeading}>Currency: </p>
          <FormControl variant="outlined" size="small">
            <Select
              variant="outlined"
              MenuProps={{
                disableScrollLock: true,
                MenuListProps: { classes: { root: classes.green } },
              }}
              className={classes.green}
              onChange={(event) => {
                handleCurrencyChange(event);
              }}
              value={activeCurrency.currencyCode}
            >
              <MenuItem value="GBP">£ GBP</MenuItem>
              <MenuItem value="USD">$ USD</MenuItem>
              <MenuItem value="EUR">€ EUR</MenuItem>
            </Select>
          </FormControl>
        </div>
        {!bundlesLoading ? (
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={countryActive as any}
              classNames="display"
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
            >
              <div className={classes.accordionContainer}>
                {countryActive ? (
                  popularCountries.length && countries.length ? (
                    <>
                      <CountryBundles
                        popularCountries={popularCountries}
                        plans={plans}
                        searchQuery={searchQuery}
                        countries={countries}
                        filteredCountries={filteredCountries}
                        showAll={showAll}
                        activeCurrency={activeCurrency}
                        introPurchased={introPurchased}
                        purchasedBundles={purchasedBundles}
                        setZonalError={setZonalError}
                      />
                      <button
                        className={classes.seeHowItWorks}
                        style={{ margin: "35px auto" }}
                        onClick={showAllCountries}
                      >
                        {showAll ? "Hide all countries" : "Show all countries"}
                      </button>
                    </>
                  ) : (
                    ""
                  )
                ) : zones.length ? (
                  zones.map((zone: any) => (
                    <CountryAccordion
                      iconUrl={zone.iconUrl}
                      countryName={zone.zoneName}
                      plans={plans}
                      zone
                      zoneId={zone.zoneId}
                      activeCurrency={activeCurrency}
                      introPurchased={introPurchased}
                      purchasedBundles={purchasedBundles}
                      setZonalError={setZonalError}
                      key={"accordion-" + zone.zoneName}
                    />
                  ))
                ) : (
                  ""
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        ) : (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress
              style={{
                color: "#20ce88",
                width: 60,
                height: 60,
                margin: "100px 0",
              }}
            />
          </div>
        )}
      </section>

      {
        ///////////////////////////////////////////////////////////////////
        //*********************  DISCOVER SECTION ***********************//
      }

      <section className={classes.discoverSection}>
        <h1 className={classes.discoverHeading}>Discover more</h1>
        <p className={classes.discoverTag}>
          Explore new destinations, find new adventures and learn all you need
          to know about travel eSIMs
        </p>
        <div className={classes.blogContainer}>
          {blogPosts ? (
            blogPosts
              .slice(-6)
              .map((blog: any, index: number) => (
                <BlogPost
                  title={blog.title}
                  image={blog.summaryImage}
                  slug={blog.slug}
                  key={"blog-" + blog.slug}
                />
              ))
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gridColumn: "1 / -1",
              }}
            >
              <CircularProgress
                style={{
                  color: "#20ce88",
                  width: 60,
                  height: 60,
                  margin: "100px auto",
                }}
              />
            </div>
          )}
        </div>
        <Link to="/blog">
          <button
            className={classes.seeHowItWorks}
            style={{ margin: "30px auto 0 auto" }}
          >
            View all posts
          </button>
        </Link>
      </section>
    </MainLayout>
  );
}

export default Home;

const formatPrice = (priceNumber: number) => {
  return priceNumber.toFixed(2);
};

const Bundle = ({
  type,
  countryName,
  countryCode,
  planName,
  zoneId,
  data,
  time,
  price,
  activeCurrency,
  id,
  activationDescription,
  validity,
  countries,
  introPurchased,
  purchasedBundles,
  setZonalError,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [countriesModalOpen, setCountriesModalOpen] = useState(false);

  const loggedIn = useSelector((state: any) => state.loggedIn);
  const navigate = useNavigate();

  const introPlanPurchased = useSelector(
    (state: any) => state.userInfo.introPlanPurchased
  );

  const modalStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "auto",
      width: "600px",
      maxWidth: "90%",
      maxHeight: "90vh",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, .3) 0px 5px 15px",
      padding: "50px 30px",
      border: "none",
      borderRadius: "15px",
      overflowY: "auto",
      overflowX: "hidden",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  };

  const getCountryQuery = () => {
    if (zoneId) {
      return "&zone=" + zoneId;
    } else if (countryName === "QAR") {
      return "&country=QA";
    } else {
      return "&country=" + countryCode;
    }
  };

  const handleSelect = () => {
    let alreadyPurchased = false;
    if (purchasedBundles) {
      purchasedBundles.forEach((bundle: any) => {
        let test = countries[0].countryCode;
        bundle.countries.forEach((bundCountry: any) => {
          if (bundCountry.countryCode === test) {
            alreadyPurchased = true;
          }
        });
      });
    }
    if (!alreadyPurchased) {
      dispatch({
        type: "set",
        buyPlan: { id: id, currency: activeCurrency.currencyCode },
      });
      if (loggedIn) {
        navigate(
          "/buyplan/" +
            id.toString() +
            "?currency=" +
            activeCurrency.currencyCode +
            getCountryQuery()
        );
      } else {
        navigate(
          "/login?id=" +
            id.toString() +
            "&currency=" +
            activeCurrency.currencyCode +
            getCountryQuery()
        );
      }
    } else {
      setZonalError({
        show: true,
        continue: () => {
          navigate(
            "/buyplan/" +
              id.toString() +
              "?currency=" +
              activeCurrency.currencyCode +
              getCountryQuery()
          );
        },
      });
    }
  };

  return introPurchased && type === "Introductory" ? (
    <></>
  ) : (
    <>
      <Modal
        isOpen={countriesModalOpen}
        onRequestClose={() => {
          setCountriesModalOpen(false);
        }}
        style={modalStyles}
      >
        <button
          className={classes.countriesModalClose}
          onClick={() => {
            setCountriesModalOpen(false);
          }}
        >
          <BsX />
        </button>
        {countries.map((country: any) => (
          <div
            className={classes.countryList}
            key={"flag-of-" + country.countryName}
          >
            <img
              src={country.iconUrl}
              alt={"the flag of " + country.countryName}
            />
            <span>{country.countryName}</span>
          </div>
        ))}
      </Modal>

      <div className={classes.bundle}>
        <div className={classes.badge}>{type}</div>
        <div className={classes.bundleInfo}>
          <div>
            <h4>{planName}</h4>
            <span>
              {data.toString() + ".00"}GB&nbsp;&nbsp;•&nbsp;&nbsp;{time} Days
            </span>
          </div>
          <div>{activeCurrency.symbol + formatPrice(price)}</div>
        </div>
        <Collapse in={expanded} unmountOnExit>
          <div className={classes.viewCountries}>
            <span>Where can I use this bundle?</span>
            <IconButton
              onClick={() => {
                setCountriesModalOpen(true);
              }}
            >
              <InfoOutlined style={{ color: "#175458" }} />
            </IconButton>
          </div>
          <div className={classes.activationInfo}>
            <h5>Activation</h5>
            <p>{activationDescription}</p>
          </div>
          <div className={classes.activationInfo}>
            <h5>How long is my bundle valid?</h5>
            <p>{validity} days from activation</p>
          </div>
        </Collapse>
        <div style={{ display: "flex", alignItems: "center", marginTop: 40 }}>
          <button className={classes.selectBtn} onClick={handleSelect}>
            Select
          </button>
          <button
            className={classes.bundleExpandBtn}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {!expanded ? (
              <KeyboardArrowDownRounded />
            ) : (
              <KeyboardArrowUpRounded />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

const CountryAccordion = ({
  iconUrl,
  countryName,
  countryCode = "",
  plans,
  zone,
  zoneId = 0,
  activeCurrency,
  introPurchased,
  purchasedBundles,
  setZonalError,
}: any) => {
  const classes = useStyles();

  const ExpandIcon = () => {
    return (
      <div>
        <div id="expand" className={classes.expandIcon}>
          <span>+</span>
        </div>
        <div id="collapse" className={classes.collapseIcon}>
          <span>-</span>
        </div>
      </div>
    );
  };

  const [thisPlans, setThisPlans] = useState([]);

  const handleOpen = (e: any, expanded: any) => {
    if (expanded && !thisPlans.length) {
      if (zone) {
        ApiGet("/plans?zoneId=" + zoneId)
          .then((response) => {
            setThisPlans(response.data.plans);
          })
          .catch((error) => {});
      } else {
        ApiGet("/plans?countryCode=" + countryCode)
          .then((response) => {
            setThisPlans(response.data.plans);
          })
          .catch((error) => {});
      }
    }
  };

  return (
    <Accordion
      className={classes.accordion}
      onChange={(e, expanded) => {
        handleOpen(e, expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        className={classes.accordionSummary}
      >
        {iconUrl ? (
          <img
            src={iconUrl}
            className={zone ? classes.zoneFlag : classes.flag}
            alt={"flag of " + countryName}
          />
        ) : (
          <div style={{ width: 27 }} />
        )}
        <h3 className={classes.countryName}>{countryName}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.bundlesContainer}>
          {thisPlans.length ? (
            thisPlans.map((plan: any) => (
              <Bundle
                type={plan.classification}
                countryName={countryName}
                planName={plan.planName}
                countryCode={countryCode}
                zoneId={zoneId}
                data={plan.dataAllowance}
                time={plan.validity}
                price={
                  plan.prices.find(
                    (price: any) =>
                      price.currencyCode === activeCurrency.currencyCode
                  ).cost
                }
                activeCurrency={activeCurrency}
                id={plan.planId}
                activationDescription={plan.activationDescription}
                validity={plan.validity}
                countries={plan.countries}
                introPurchased={introPurchased}
                purchasedBundles={purchasedBundles}
                setZonalError={setZonalError}
                key={"bundle-" + plan.planId}
              />
            ))
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gridColumn: "1 / -1",
              }}
            >
              <CircularProgress
                style={{
                  color: "#20ce88",
                  width: 60,
                  height: 60,
                  margin: "100px 0",
                }}
              />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const CountryBundles = ({
  popularCountries,
  countries,
  filteredCountries,
  plans,
  searchQuery,
  showAll,
  activeCurrency,
  introPurchased,
  purchasedBundles,
  setZonalError,
}: any) => {
  const classes = useStyles();

  return (
    <>
      {showAll ? (
        countries.map((country: any) => (
          <CountryAccordion
            iconUrl={country.iconUrl}
            countryName={country.countryName}
            countryCode={country.countryCode}
            plans={plans}
            activeCurrency={activeCurrency}
            introPurchased={introPurchased}
            purchasedBundles={purchasedBundles}
            setZonalError={setZonalError}
            key={"country-all-" + country.countryName}
          />
        ))
      ) : searchQuery ? (
        filteredCountries.length ? (
          filteredCountries.map((country: any) => (
            <CountryAccordion
              iconUrl={country.iconUrl}
              countryName={country.countryName}
              countryCode={country.countryCode}
              plans={plans}
              activeCurrency={activeCurrency}
              introPurchased={introPurchased}
              purchasedBundles={purchasedBundles}
              setZonalError={setZonalError}
              key={"country-filtered-" + country.countryName}
            />
          ))
        ) : (
          <h2
            className={classes.popularHeading}
            style={{
              margin: "0 auto",
              padding: "20px 0",
              borderTop: "2px solid #d4d4d4",
            }}
          >
            No countries found
          </h2>
        )
      ) : (
        popularCountries.map((country: any) => (
          <CountryAccordion
            iconUrl={country.iconUrl}
            countryName={country.countryName}
            countryCode={country.countryCode}
            plans={plans}
            activeCurrency={activeCurrency}
            introPurchased={introPurchased}
            purchasedBundles={purchasedBundles}
            setZonalError={setZonalError}
            key={"country-popular-" + country.countryName}
          />
        ))
      )}
    </>
  );
};

const BlogPost = ({ title, image, slug }: any) => {
  const classes = useStyles();

  return (
    <Link to={"/blog" + slug}>
      <div className={classes.blogPost}>
        <img src={image} alt="" />
        <div className={classes.blogTitle}>{title}</div>
      </div>
    </Link>
  );
};

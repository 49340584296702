import red from "@material-ui/core/colors/red";
import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#20ce88",
    },
    secondary: {
      main: "#175458",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: `"Urbane", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import { ApiGetAuth, ApiPostAuth } from "../../pages/api/api";
import {
  Menu,
  MenuItem,
  Collapse,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import PaymentOutlined from "@material-ui/icons/PaymentOutlined";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import { Drawer } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

interface IHeaderProps {
  transparent: boolean;
}

const Header: React.FC<IHeaderProps> = ({ transparent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state: any) => state.loggedIn);
  const userInfo = useSelector((state: any) => state.userInfo);

  const location = useLocation();
  const navigate = useNavigate();
  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(location.pathname.slice(1));
  }, []);

  useEffect(() => {
    ApiGetAuth("/users")
      .then((response) => {
        dispatch({ type: "set", loggedIn: true });
        dispatch({ type: "set", userData: response });
        dispatch({
          type: "set",
          userInfo: {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            introPlanPurchased: response.data.introPlanPurchased,
          },
        });
      })
      .catch((error) => {});
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    ApiPostAuth("/users/logout", {})
      .then((response: any) => {
        localStorage.clear();
        dispatch({ type: "set", loggedIn: false });
        dispatch({ type: "set", userData: null as any });
        dispatch({
          type: "set",
          userInfo: {
            firstName: "",
            lastName: "",
            introPlanPurchased: false,
          },
        });
        navigate("/login");
      })
      .catch((error: any) => {
        localStorage.clear();
        dispatch({ type: "set", loggedIn: false });
        dispatch({ type: "set", userData: null as any });
        dispatch({
          type: "set",
          userInfo: {
            firstName: "",
            lastName: "",
            introPlanPurchased: false,
          },
        });
        navigate("/login");
      });
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <header
      className={classes.header}
      style={{ background: transparent ? "none" : "#175458" }}
    >
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={() => {
          setSidebarOpen(false);
        }}
        style={{ overflowY: "scroll" }}
      >
        <div
          style={{
            background: "#175458",
            height: "100%",
            padding: "30px 20px",
          }}
        >
          <ul style={{ margin: 0, listStyleType: "none", padding: 0 }}>
            <li style={{ marginBottom: 20 }}>
              <Link to="/">
                <img
                  src="/BreatheSIM-Green-RGB_Linear.svg"
                  className={classes.logo}
                  alt="BreatheSIM Logo"
                />
              </Link>
            </li>
            <li
              className={
                path === "about" ? classes.barActiveLink : classes.barLink
              }
            >
              <Link to="/about">ABOUT</Link>
            </li>
            <li
              className={
                path === "faqs" ? classes.barActiveLink : classes.barLink
              }
            >
              <Link to="/faqs">FAQs</Link>
            </li>
            <li
              className={
                path.includes("blog") ? classes.barActiveLink : classes.barLink
              }
            >
              <Link to="/blog">BLOG</Link>
            </li>
            <li>
              {loggedIn ? (
                <>
                  <div
                    className={classes.initials}
                    onClick={(e: any) => {
                      handleOpenMenu(e);
                    }}
                  >
                    {userInfo.firstName[0] + userInfo.lastName[0]}
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    disableScrollLock
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Link to="/account/bundles">
                      <MenuItem
                        className={classes.menuItem}
                        style={{ color: "#175458" }}
                      >
                        <PersonIcon />
                        My Bundles
                      </MenuItem>
                    </Link>
                    <Link to="/account/payment">
                      <MenuItem
                        className={classes.menuItem}
                        style={{ color: "#175458" }}
                      >
                        <PaymentOutlined />
                        Payment
                      </MenuItem>
                    </Link>
                    <Link to="/account/support">
                      <MenuItem
                        className={classes.menuItem}
                        style={{ color: "#175458" }}
                      >
                        <HelpOutlineRounded />
                        Support
                      </MenuItem>
                    </Link>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleLogOut}
                      style={{ color: "#175458" }}
                    >
                      <LockIcon />
                      Log Out
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Link to="/login">
                  <button className={classes.loginBtn}>LOGIN</button>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </Drawer>
      <Link to="/" style={{ height: "100%" }}>
        <div className={classes.logoWrapper}>
          <img
            src="/BreatheSIM-Green-RGB_Linear.svg"
            className={classes.logo}
            alt="BreatheSIM Logo"
          />
        </div>
      </Link>
      <nav style={{ display: "flex", alignItems: "center" }}>
        <ul style={{ margin: 0 }}>
          <li className={path === "about" ? classes.activeLink : classes.link}>
            <Link to="/about">ABOUT</Link>
          </li>
          <li className={path === "faqs" ? classes.activeLink : classes.link}>
            <Link to="/faqs">FAQs</Link>
          </li>
          <li
            className={
              path.includes("blog") ? classes.activeLink : classes.link
            }
          >
            <Link to="/blog">BLOG</Link>
          </li>
          <li>
            {loggedIn ? (
              <>
                <div
                  className={classes.initials}
                  onClick={(e: any) => {
                    handleOpenMenu(e);
                  }}
                >
                  {userInfo.firstName &&
                    userInfo.firstName[0] + userInfo.lastName[0]}
                </div>
                <Menu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  disableScrollLock
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Link to="/account/bundles">
                    <MenuItem
                      className={classes.menuItem}
                      style={{ color: "#175458" }}
                    >
                      <PersonIcon />
                      My Bundles
                    </MenuItem>
                  </Link>
                  <Link to="/account/payment">
                    <MenuItem
                      className={classes.menuItem}
                      style={{ color: "#175458" }}
                    >
                      <PaymentOutlined />
                      Payment
                    </MenuItem>
                  </Link>
                  <Link to="/account/support">
                    <MenuItem
                      className={classes.menuItem}
                      style={{ color: "#175458" }}
                    >
                      <HelpOutlineRounded />
                      Support
                    </MenuItem>
                  </Link>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleLogOut}
                    style={{ color: "#175458" }}
                  >
                    <LockIcon />
                    Log Out
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Link to="/login">
                <button className={classes.loginBtn}>LOGIN</button>
              </Link>
            )}
          </li>
        </ul>
        <IconButton
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MenuIcon className={classes.menu} />
        </IconButton>
      </nav>
    </header>
  );
};

export default Header;

export const Shape: React.FC = () => {
  return (
    <svg viewBox="0 0 450 216">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="450"
          height="300.46"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="450"
            height="300.46"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 169.932C450 183.738 438.808 194.935 425.001 194.941C376.705 194.96 273.994 195 259 195C239.29 195 233.538 216 225.16 216C216.782 216 207.29 195.43 191 194.93C178.57 194.548 73.9053 194.75 24.9989 194.866C11.1924 194.899 0 183.738 0 169.932L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape2: React.FC = () => {
  return (
    <svg viewBox="0 0 450 250">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="450"
          height="300.46"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="450"
            height="300.46"
          />
        </pattern>
      </defs>
      <path
        d="M0 1.14441e-05L450 0L450 203.871C450 217.677 438.808 228.874 425.001 228.88C376.705 228.899 273.994 228.94 259 228.94C239.29 228.94 233.538 250 225.16 250C216.783 250 207.29 229.371 191 228.869C178.57 228.486 73.9053 228.688 24.9989 228.805C11.1924 228.838 3.04108e-12 217.677 2.84819e-12 203.871L0 1.14441e-05Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape3: React.FC = () => {
  return (
    <svg viewBox="0 0 450 272">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="450"
          height="300.46"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="450"
            height="300.46"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 225.871C450 239.677 438.808 250.874 425.001 250.88C376.705 250.899 273.994 250.94 259 250.94C239.29 250.94 233.538 272 225.161 272C216.783 272 207.29 251.371 191 250.869C178.57 250.486 73.9053 250.688 24.9989 250.805C11.1924 250.838 1.4578e-05 239.677 1.37383e-05 225.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape4: React.FC = () => {
  return (
    <svg viewBox="0 0 450 315">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="471.77"
          height="315"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="471.77"
            height="315"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 268.871C450 282.677 438.808 293.874 425.001 293.88C376.705 293.899 273.994 293.94 259 293.94C239.29 293.94 233.538 315 225.16 315C216.783 315 207.29 294.371 191 293.869C178.57 293.487 73.9053 293.688 24.9989 293.805C11.1924 293.838 3.07566e-12 282.677 2.92544e-12 268.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape5: React.FC = () => {
  return (
    <svg viewBox="0 0 450 344">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="515.21"
          height="344"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="515.21"
            height="344"
          />
        </pattern>
      </defs>
      <path
        d="M0 7.62939e-06L450 0L450 297.871C450 311.677 438.808 322.874 425.001 322.88C376.705 322.899 273.994 322.94 259 322.94C239.29 322.94 233.538 344 225.161 344C216.783 344 207.29 323.371 191 322.869C178.57 322.486 73.9053 322.688 24.9989 322.805C11.1924 322.838 1.47298e-05 311.677 1.40773e-05 297.871L0 7.62939e-06Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape6: React.FC = () => {
  return (
    <svg viewBox="0 0 450 368">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="551.15"
          height="368"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="551.15"
            height="368"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 321.871C450 335.677 438.808 346.874 425.001 346.88C376.705 346.899 273.994 346.94 259 346.94C239.29 346.94 233.538 368 225.161 368C216.783 368 207.29 347.371 191 346.869C178.57 346.486 73.9053 346.688 24.9989 346.805C11.1924 346.838 1.47664e-05 335.677 1.41591e-05 321.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape7: React.FC = () => {
  return (
    <svg viewBox="0 0 450 399">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="597.58"
          height="399"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="597.58"
            height="399"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 352.871C450 366.677 438.808 377.874 425.001 377.88C376.705 377.899 273.994 377.94 259 377.94C239.29 377.94 233.538 399 225.161 399C216.783 399 207.29 378.371 191 377.869C178.57 377.486 73.9053 377.688 24.9989 377.805C11.1924 377.838 1.48068e-05 366.677 1.42493e-05 352.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape8: React.FC = () => {
  return (
    <svg viewBox="0 0 450 434">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="650"
          height="434"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="650"
            height="434"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 387.871C450 401.677 438.808 412.874 425.001 412.88C376.705 412.899 273.994 412.94 259 412.94C239.29 412.94 233.538 434 225.161 434C216.783 434 207.29 413.371 191 412.869C178.57 412.486 73.9053 412.688 24.9989 412.805C11.1924 412.838 1.48451e-05 401.677 1.43349e-05 387.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape9: React.FC = () => {
  return (
    <svg viewBox="0 0 450 484">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="724.88"
          height="484"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="724.88"
            height="484"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 437.871C450 451.678 438.808 462.874 425.001 462.88C376.705 462.899 273.994 462.94 259 462.94C239.29 462.94 233.538 484 225.161 484C216.783 484 207.29 463.371 191 462.869C178.57 462.486 73.9053 462.688 24.9989 462.805C11.1924 462.838 1.48898e-05 451.678 1.44347e-05 437.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape10: React.FC = () => {
  return (
    <svg viewBox="0 0 450 563">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="843.2"
          height="563"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="843.2"
            height="563"
          />
        </pattern>
      </defs>
      <path
        d="M0 1.18017e-05L450 0L450 516.87C450 530.677 438.808 541.874 425.001 541.88C376.705 541.899 273.994 541.939 259 541.939C239.29 541.939 233.538 563 225.16 563C216.783 563 207.29 542.37 191 541.869C178.57 541.486 73.9053 541.688 24.9989 541.805C11.1924 541.838 3.1314e-12 530.677 3.04993e-12 516.87L0 1.18017e-05Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape11: React.FC = () => {
  return (
    <svg viewBox="0 0 450 667">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="998.96"
          height="667"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="998.96"
            height="667"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 620.871C450 634.677 438.808 645.874 425.001 645.88C376.705 645.899 273.994 645.939 259 645.939C239.29 645.939 233.538 667 225.16 667C216.783 667 207.29 646.37 191 645.869C178.57 645.486 73.9053 645.688 24.9989 645.805C11.1924 645.838 3.14203e-12 634.677 3.07368e-12 620.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape12: React.FC = () => {
  return (
    <svg viewBox="0 0 450 744">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="1114.29"
          height="744"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="1114.29"
            height="744"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 696.871C450 710.677 438.808 721.874 425.001 721.88C376.705 721.899 273.994 721.939 259 721.939C239.29 721.939 233.538 743 225.161 743C216.783 743 207.29 722.371 191 721.869C178.57 721.486 73.9053 721.688 24.9989 721.805C11.1924 721.838 6.29574e-12 710.677 6.17343e-12 696.871L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape13: React.FC = () => {
  return (
    <svg viewBox="0 0 450 813">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="1218.89"
          height="813"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="1218.89"
            height="813"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 765.873C450 779.679 438.808 790.876 425.001 790.882C376.705 790.901 273.994 790.941 259 790.941C239.291 790.941 233.538 812 225.161 812C216.783 812 207.29 791.373 191 790.871C178.571 790.488 73.9054 790.69 24.9989 790.807C11.1924 790.84 0 779.679 0 765.873L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape14: React.FC = () => {
  return (
    <svg viewBox="0 0 450 905">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="1356.82"
          height="905"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="1356.82"
            height="905"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 857.873C450 871.68 438.808 882.877 425.001 882.882C376.705 882.901 273.994 882.942 259 882.942C239.291 882.942 233.538 904 225.161 904C216.783 904 207.29 883.373 191 882.871C178.571 882.488 73.9054 882.69 24.9989 882.808C11.1924 882.841 0 871.68 0 857.873L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape15: React.FC = () => {
  return (
    <svg viewBox="0 0 450 1006">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="1506.68"
          height="1006"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="1506.68"
            height="1006"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 958.872C450 972.679 438.808 983.876 425.001 983.881C376.705 983.901 273.994 983.941 259 983.941C239.29 983.941 233.538 1005 225.161 1005C216.783 1005 207.29 984.373 191 983.87C178.571 983.488 73.9053 983.69 24.9989 983.807C11.1924 983.84 4.74161e-12 972.679 4.6743e-12 958.872L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape16: React.FC = () => {
  return (
    <svg viewBox="0 0 450 1102">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="1652.17"
          height="1102"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="1652.17"
            height="1102"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 1054.87C450 1068.68 438.808 1079.88 425.001 1079.88C376.705 1079.9 273.994 1079.94 259 1079.94C239.291 1079.94 233.538 1101 225.161 1101C216.783 1101 207.29 1080.37 191 1079.87C178.571 1079.49 73.9054 1079.69 24.9989 1079.81C11.1924 1079.84 0 1068.68 0 1054.87L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape17: React.FC = () => {
  return (
    <svg viewBox="0 0 450 1219">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="1825.69"
          height="1219"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="1825.69"
            height="1219"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 1172.37C450 1186.18 438.808 1197.38 425.001 1197.38C376.705 1197.4 273.994 1197.44 259 1197.44C239.29 1197.44 233.538 1218.5 225.161 1218.5C216.783 1218.5 207.29 1197.87 191 1197.37C178.571 1196.99 73.9053 1197.19 24.9989 1197.31C11.1924 1197.34 4.75133e-12 1186.18 4.69603e-12 1172.37L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape18: React.FC = () => {
  return (
    <svg viewBox="0 0 450 1356">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="2032.98"
          height="1356"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="2032.98"
            height="1356"
          />
        </pattern>
      </defs>
      <path
        d="M0 0L450 0L450 1309.38C450 1323.18 438.808 1334.38 425.001 1334.38C376.705 1334.4 273.994 1334.44 259 1334.44C239.291 1334.44 233.538 1355.5 225.161 1355.5C216.783 1355.5 207.29 1334.88 191 1334.37C178.571 1333.99 73.9054 1334.19 24.9989 1334.31C11.1924 1334.34 0 1323.18 0 1309.38L0 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape19: React.FC = () => {
  return (
    <svg viewBox="0 0 450 1473">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="2208.4"
          height="1473"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="2208.4"
            height="1473"
          />
        </pattern>
      </defs>
      <path
        d="M0.000183105 0L450 0L450 1426.38C450 1440.18 438.808 1451.38 425.001 1451.38C376.705 1451.4 273.994 1451.44 259 1451.44C239.291 1451.44 233.538 1472.5 225.161 1472.5C216.783 1472.5 207.29 1451.88 191 1451.37C178.571 1450.99 73.9054 1451.19 24.9989 1451.31C11.1924 1451.34 1.41204e-06 1440.18 3.15388e-06 1426.38L0.000183105 0Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Shape20: React.FC = () => {
  return (
    <svg viewBox="0 0 450 1643">
      <defs>
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="2463.27"
          height="1643"
        >
          <image
            href="/BreatheSIM Homepage Image@4x.png"
            x="0"
            y="0"
            width="2463.27"
            height="1643"
          />
        </pattern>
      </defs>
      <path
        d="M0.000183105 4.64219e-06L450 0L450 1596.38C450 1610.18 438.808 1621.38 425.001 1621.39C376.705 1621.41 273.994 1621.45 259 1621.45C239.291 1621.45 233.538 1642.5 225.161 1642.5C216.783 1642.5 207.29 1621.88 191 1621.37C178.571 1620.99 73.9054 1621.19 24.9989 1621.31C11.1924 1621.34 1.26399e-06 1610.18 2.82319e-06 1596.38L0.000183105 4.64219e-06Z"
        id="Rectangle"
        fill="url(#img1)"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
};

export const Visa: React.FC = () => {
  return (
    <svg
      width="57"
      height="19"
      viewBox="0 0 57 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8194 18.0794H20.2949L23.1227 0.461365H27.6473L24.8194 18.0794Z"
        fill="currentColor"
      />
      <path
        d="M16.489 0.461365L12.1745 12.5799L11.6655 9.96943L10.1439 2.04786C10.1439 2.04786 9.96075 0.461365 7.99744 0.461365H0.868644L0.785156 0.759003C0.785156 0.759003 2.96662 1.21775 5.51704 2.77421L9.44636 18.0794H14.1594L21.3582 0.461365H16.489Z"
        fill="currentColor"
      />
      <path
        d="M52.0743 18.0794H56.2271L52.6048 0.461365H48.9664C47.2858 0.461365 46.8792 1.7748 46.8792 1.7748L40.1328 18.0794H44.8485L45.7911 15.4634H51.541L52.0716 18.0794H52.0743ZM47.0973 11.8481L49.4754 5.25362L50.8139 11.8481H47.1H47.0973Z"
        fill="currentColor"
      />
      <path
        d="M40.4849 4.69735L41.1313 0.912693C41.1313 0.912693 39.1383 0.145386 37.0619 0.145386C34.8158 0.145386 29.486 1.14207 29.486 5.97802C29.486 10.53 35.7423 10.5873 35.7423 12.9766C35.7423 15.3659 30.1297 14.94 28.2768 13.4326L27.6035 17.3866C27.6035 17.3866 29.6234 18.3833 32.7098 18.3833C35.7961 18.3833 40.4553 16.7613 40.4553 12.3513C40.4553 7.94135 34.1425 7.34334 34.1425 5.3527C34.1425 3.36207 38.5485 3.61602 40.4849 4.69735Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        header: {
            height: "99px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 50px",
            justifyContent: "space-between",
            position: 'fixed',
            top: 0,
            zIndex: 1299,
            transition: 'background 0.5s ease',
            '& ul': {
                listStyleType: 'none',
                display: 'flex',
                '@media (max-width: 850px)': {
                    display: 'none'
                },
            },
            '@media (max-width: 550px)': {
                padding: '0 20px'
            },
            '@media (max-width: 960px)': {
                height: '80px'
            },
            '@media (max-width: 450px)': {
                height: '60px'
            }
        },
        link: {
            margin: '0 20px',
            fontFamily: 'Termina',
            color: '#fff',
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '& a': {
                color: '#fff!important',
                transition: 'all 0.2s ease',
                '&:hover': {
                    color: '#20ce88!important'
                }
            },
            '@media (max-width: 415px)': {
                margin: 0,
                fontSize: '12px'
            },
            '&:hover': {
                color: '#20ce88'
            }
        },
        activeLink: {
            margin: '0 20px',
            fontFamily: 'Termina',
            color: '#20ce88',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            transition: 'all 0.2s ease',
            '@media (max-width: 415px)': {
                margin: 0,
                fontSize: '12px'
            },
            '& a': {
                cursor: 'auto'
            }
        },
        barLink: {
            margin: '0 20px',
            marginBottom: 30,
            fontFamily: 'Termina',
            color: '#fff',
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
                color: '#20ce88'
            }
        },
        barActiveLink: {
            margin: '0 20px',
            marginBottom: 30,
            fontFamily: 'Termina',
            color: '#20ce88',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            transition: 'all 0.2s ease',
            '& a': {
                cursor: 'auto'
            }
        },
        logoWrapper: {
            height: '100%',
            width: 'auto',
            display: 'flex',
            justifyContent  : 'flex-start',
            alignItems: 'center'
        },
        logo: {
            height: '50%',
            width: 'auto',
            cursor: 'pointer',
            '@media (max-width: 415px)': {
                height: 'auto',
                width: '170px'
            }
        },
        loginBtn: {
            background: '#20ce88',
            borderRadius: '100px',
            fontFamily: 'Termina',
            color: '#175458',
            fontSize: '1rem',
            fontWeight: 500,
            padding: '10px 30px',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            border: 'none',
            '&:hover': {
                background: '#175458',
                color: '#20ce88'
            }
        },
        menu: {
            display: 'none!important',
            color: '#20ce88',
            width: '30px',
            height: '30px',
            '@media (max-width: 850px)': {
                display: 'block!important'
            },
        },
        initials: {
            width: 45,
            height: 45,
            borderRadius: '100%',
            backgroundColor: '#20ce88',
            color: '#175458',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 16,
            letterSpacing: 1,
            marginLeft: 20,
            fontFamily: 'Termina, Roboto, sans-serif',
            cursor: 'pointer',
            transition: 'all 0.15s ease',
            border: 'none',
            '&:hover': {
                background: '#175458',
                color: '#20ce88'
            }
        },
        menuItem: {
            background: '#fff',
            color: '#175458',
            padding: '10px 30px',
            borderRadius: 5,
            display: 'flex',
            fontFamily: 'Termina, Roboto, sans-serif !important',
            '& svg': {
                marginRight: 10,
            }
        },
    })
)
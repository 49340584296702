import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    modalContainer: {
      width: "100%",
      height: "100%",
      textAlign: "center",
      overflowY: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& h2": {
        color: "#175458",
        fontFamily: "Termina, Roboto, sans-serif",
        fontSize: 28,
        fontWeight: 600,
        "@media (max-width: 930px)": {
          fontSize: 20,
        },
        "@media (max-width: 400px)": {
          fontSize: 18,
        },
      },
    },
    content: {
      padding: "50px",
      color: "#175458",
      "@media (max-width: 570px)": {
        padding: "24px",
      },
      "& p": {
        fontSize: 16,
        lineHeight: "24px",
      },
    },
    buttons: {
      display: "flex",
      "@media (max-width: 570px)": {
        flexDirection: "column-reverse",
        alignItems: "center",
      },
    },
    closeButton: {
      background: "none",
      border: "2px solid #175458",
      color: "#175458",
      borderRadius: "60px",
      fontSize: "1rem",
      fontWeight: 500,
      height: 50,
      transition: "all 0.3s ease",
      margin: "0 auto",
      marginTop: "30px",
      fontFamily: "Termina, sans-serif",
      cursor: "pointer",
      marginRight: 16,
      padding: "0 24px",
      "@media (max-width: 570px)": {
        width: "100%",
        margin: "0 auto",
        marginTop: "30px",
      },
    },
    button: {
      background: "#20ce88",
      border: "none",
      color: "#175458",
      borderRadius: "60px",
      fontSize: "1rem",
      fontWeight: 500,
      height: 50,
      width: "100%",
      maxWidth: 500,
      transition: "all 0.3s ease",
      margin: "0 auto",
      marginTop: "30px",
      fontFamily: "Termina, sans-serif",
      cursor: "pointer",
    },
  })
);

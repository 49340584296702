import React from 'react';
import { useStyles } from './style';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Visa } from '../Svg/Visa';
import { Mastercard } from '../Svg/Mastercard';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { ApiGetAuth, ApiPostAuth } from '../../pages/api/api'
import { useNavigate } from 'react-router-dom'

const Footer: React.FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedIn = useSelector((state: any) => state.loggedIn);
    const userInfo = useSelector((state: any) => state.userInfo);

    const handleLogOut = () => {
        ApiPostAuth('/users/logout', {})
            .then((response: any) => {
                localStorage.clear();
                dispatch({ type: 'set', loggedIn: false });
                dispatch({
                    type: 'set', userInfo: {
                        firstName: '',
                        lastName: '',
                        introPlanPurchased: false
                    }
                });
                navigate('/login');
            })
            .catch((error: any) => {
                localStorage.clear();
                dispatch({ type: 'set', loggedIn: false });
                dispatch({
                    type: 'set', userInfo: {
                        firstName: '',
                        lastName: '',
                        introPlanPurchased: false
                    }
                });
                navigate('/login');
            })

    }

    return (
        <footer className={classes.footer}>
            <div className={classes.section1}>
                <img src='/BreatheSIM-White-RGB_Linear.svg' alt='logo' className={classes.logo} />
                <div className={classes.links}>
                    <Link to='/about'>
                        ABOUT
                    </Link>
                    {/*<p>AFFILIATES</p>*/}
                    <Link to='/blog'>
                        BLOG
                    </Link>
                    {
                        loggedIn ?
                            <a onClick={handleLogOut}>LOG OUT</a>
                            :
                            <Link to='/login'>
                                LOGIN   
                            </Link>
                    }
                </div>
                <div className={classes.social}>
                    <p>FOLLOW US</p>
                    <a href="https://www.instagram.com/gobreathesim/" target='_blank'><InstagramIcon /></a>
                    <a href="https://www.facebook.com/GoBreatheSIM" target='_blank'><FacebookIcon /></a>
                    <a href="https://www.youtube.com/channel/UCKWY8TG7y25ch76Gnl32QoQ" target='_blank'><YouTubeIcon /></a>
                    <a href="https://twitter.com/GoBreatheSIM" target='_blank'><TwitterIcon /></a>
                </div>
            </div>
            <div className={classes.section2}>
                <div className={classes.terms}>
                    <p>©2021 BREATHESIM</p>
                    <Link to='/privacypolicy'>
                        <div className={classes.termsLink}>PRIVACY POLICY</div>
                    </Link>
                    <Link to='/termsandconditions'>
                        <div className={classes.termsLink}>TERMS & CONDITIONS</div>
                    </Link>
                </div>
                <div className={classes.payments}>
                    <Visa />
                    <img src='/mastercard.png' alt='mastercard' />
                    <img src='/Apple_Pay.svg' alt='apple pay' style={{ height: '1.9em' }} />
                    <img src='/google-pay.svg' alt='google pay' />
                </div>
            </div>
        </footer>
    )
}

export default Footer;
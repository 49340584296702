import { createStore } from 'redux'

const initialState = {
    loggedIn: false,
    userInfo: {
        firstName: '',
        lastName: '',
        introPlanPurchased: false
    },
    buyPlan: {
        id: 0,
        currency: 'GBP'
    },
    zones: null as any,
    countries: null as any,
    plans: null as any,
    blogs: null as any,
    userData: null as any
}

const changeState = (state = initialState, { type, ...rest }: any) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

const store = createStore(changeState)
export default store

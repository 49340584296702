import axios from "axios";

const queryCors = "https://manx.mobiliseconnect.com:7460";

export const ApiPost = (url: string, parameters: { [propName: string]: any }) =>
  axios.post(queryCors + url, parameters, {
    headers: {
      apikey: "CfUg-rVBr3E_7k8x3-dMj_Gx6XpdF9",
      companycode: "Web",
    },
  });

export const ApiPostAuth = (
  url: string,
  parameters: { [propName: string]: any }
) =>
  axios.post(queryCors + url, parameters, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth") || ""}`,
      apikey: "CfUg-rVBr3E_7k8x3-dMj_Gx6XpdF9",
      companycode: "Web",
    },
  });

export const ApiGet = (url: string) =>
  axios.get(queryCors + url, {
    headers: {
      apikey: "CfUg-rVBr3E_7k8x3-dMj_Gx6XpdF9",
      companycode: "Web",
    },
  });

export const ApiGetAuth = (url: string) =>
  axios.get(queryCors + url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth") || ""}`,
      apikey: "CfUg-rVBr3E_7k8x3-dMj_Gx6XpdF9",
      companycode: "Web",
    },
  });

export const ApiDelete = (
  url: string,
  parameters: { [propName: string]: any }
) =>
  axios.delete(queryCors + url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth") || ""}`,
      apikey: "CfUg-rVBr3E_7k8x3-dMj_Gx6XpdF9",
      companycode: "Web",
    },
    data: parameters,
  });

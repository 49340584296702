import "./styles/globals.css";
import { CircularProgress } from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/home";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";

const loading = (
  <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
    <Header />
    <CircularProgress style={{ color: "#20ce88", width: 25, height: 25 }} />
  </div>
);

function MyApp({ Component, pageProps }) {
  /*const router = useRouter();
    const handleRouteChange = (url) => {
        window.gtag('config', 'G-ZB46JQE5L7', {
            page_path: url,
        });
    };
    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);*/

  // Pages
  const About = React.lazy(() => import("./pages/about"));
  const MyBundles = React.lazy(() => import("./pages/account/bundles"));
  const Payment = React.lazy(() => import("./pages/account/payment"));
  const Referral = React.lazy(() => import("./pages/account/referral"));
  const AccountSupport = React.lazy(() => import("./pages/account/support"));
  const Blogs = React.lazy(() => import("./pages/blogs"));
  const Blog = React.lazy(() => import("./pages/blog/[id]"));
  const BuyPlan = React.lazy(() => import("./pages/buyplan/[id]"));
  const Faqs = React.lazy(() => import("./pages/faqs"));
  const ForgotPassword = React.lazy(() => import("./pages/forgotpassword"));
  const Home = React.lazy(() => import("./pages/home"));
  const Login = React.lazy(() => import("./pages/login"));
  const Privacy = React.lazy(() => import("./pages/privacypolicy"));
  const ResetPassword = React.lazy(() => import("./pages/resetpassword"));
  const SignUp = React.lazy(() => import("./pages/signup"));
  const Support = React.lazy(() => import("./pages/support"));
  const Terms = React.lazy(() => import("./pages/termsandconditions"));
  const Page404 = React.lazy(() => import("./404"));
  const PromoFrance = React.lazy(() => import("./pages/1gbfrance"));
  /*const PromoRegUsa = React.lazy(() => import("./pages/1gbfreeusa"));*/
  const Success = React.lazy(() => import("./pages/success"));

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" name="Home" element={<Home />} />
            <Route exact path="/about" name="About" element={<About />} />
            <Route
              exact
              path="/account/bundles"
              name="My Bundles"
              element={<MyBundles />}
            />
            <Route
              exact
              path="/account/payment"
              name="Payment"
              element={<Payment />}
            />
            <Route
              exact
              path="/account/support"
              name="Support"
              element={<AccountSupport />}
            />
            <Route
              exact
              path="/account/referral"
              name="Referral"
              element={<Referral />}
            />
            <Route exact path="/blog" name="Blog" element={<Blogs />} />
            <Route exact path="/blog/:id" name="Blog" element={<Blog />} />
            <Route
              exact
              path="/buyplan/:id"
              name="Buy Plan"
              element={<BuyPlan />}
            />
            <Route exact path="/faqs" name="FAQs" element={<Faqs />} />
            <Route
              exact
              path="/forgotpassword"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route exact path="/login" name="Login" element={<Login />} />
            <Route
              exact
              path="/privacypolicy"
              name="Privacy Policy"
              element={<Privacy />}
            />
            <Route
              exact
              path="/resetpassword"
              name="Reset Password"
              element={<ResetPassword />}
            />
            <Route exact path="/signup" name="Sign Up" element={<SignUp />} />
            <Route exact path="/support" name="Support" element={<Support />} />
            <Route
              exact
              path="/termsandconditions"
              name="Terms and Conditions"
              element={<Terms />}
            />
            {/*<Route
              exact
              path="/1gbfreeusa"
              name="Promo USA"
              element={<PromoRegUsa />}
  />*/}
            <Route
              exact
              path="/france2024"
              name="Promo"
              element={<PromoFrance />}
            />
            <Route exact path="/success" name="Success" element={<Success />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ScrollToTop>
      </React.Suspense>
    </Router>
  );
}

export default MyApp;

import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    main: {
      width: "100%",
      minHeight: "100vh",
    },
    navbar: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "fixed",
      top: 99,
      background: "rgba(32, 206, 136, 1)",
      boxShadow: "inset 2px 2px 8px rgba(23, 84, 88, 0.3)",
      height: 65,
      zIndex: 900,
      "@media (max-width: 960px)": {
        top: 80,
      },
      "@media (max-width: 450px)": {
        top: 60,
      },
      "@media(max-width: 750px)": {
        height: 45,
      },
    },
    buttons: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr auto",
      gridColumnGap: 0,
      alignItems: "center",
      justifyContent: "center",
      justifyItems: "center",
      height: 65,
      width: "100%",
      maxWidth: 1300,
      "@media(max-width: 750px)": {
        height: 45,
      },
    },
    navBtn: {
      fontFamily: "Termina, Urbane, sans-serif",
      background: "none",
      border: "none",
      fontSize: 21,
      color: "#175458",
      fontWeight: 500,
      height: "100%",
      cursor: "pointer",
      width: "100%",
      borderBottom: "4px solid transparent",
      transition: "all 0.3s ease",
      "@media(max-width: 750px)": {
        fontSize: 16,
      },
      "@media(max-width: 410px)": {
        fontSize: 12,
      },
      "&:hover": {
        borderBottom: "4px solid rgba(23, 84, 88, 0.6)",
      },
    },
    navBtnActive: {
      fontFamily: "Termina, Urbane, sans-serif",
      background: "none",
      border: "none",
      fontSize: 21,
      color: "#175458",
      fontWeight: 500,
      height: "100%",
      borderBottom: "4px solid #175458",
      width: "100%",
      transition: "all 0.3s ease",
      "@media(max-width: 750px)": {
        fontSize: 16,
      },
      "@media(max-width: 410px)": {
        fontSize: 14,
      },
    },
    menuItem: {
      background: "#fff",
      color: "#175458",
      padding: "10px 30px",
      borderRadius: 5,
      display: "flex",
      fontFamily: "Termina, Roboto, sans-serif !important",
      "& svg": {
        marginRight: 10,
      },
    },
  })
);

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        footer: {
            background: '#175458',
            width: '100%',
            minHeight: '100px',
            color: "#fff",
            fontWeight: 500,
            flexWrap: 'wrap',
            padding: '60px 60px 30px 60px',
            '& p': {
                margin: '20px 50px'
            },
            '@media (max-width: 645px)': {
                padding: '50px'
            },
        },
        inlineBlock: {
            display: 'inline-block'
        },
        logo: {
            height: '30px'
        },
        section1: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '2px solid #fff',
            paddingBottom: '30px',
            '@media (max-width: 990px)': {
                flexDirection: 'column',
            }
        },
        links: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '50px',
            cursor: 'pointer',
            '& a': {
                margin: 20,
                padding: 0,
                fontSize: '14px',
                fontWeight: 400,
                '&:hover': {
                    color: '#20ce88 !important'
                },
                '@media (max-width: 375px)': {
                    margin: 10
                },
            },
            '@media (max-width: 990px)': {
                margin: '30px 0'
            },
        },
        social: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            '& svg': {
                margin: '0 5px',
                cursor: 'pointer',
                '&:hover': {
                    color: '#20ce88'
                }
            },
            '& p': {
                margin: 0,
                marginRight: 15,
                '@media (max-width: 345px)': {
                    fontSize: '0.75rem'
                }
            },
            '@media (max-width: 990px)': {
                marginLeft: 0
            }
        },
        section2: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '30px',
            '@media (max-width: 975px)': {
                flexDirection: 'column',
            }
        },
        terms: {
            display: 'flex',
            '@media (max-width: 587px)': {
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            '& a, p': {
                margin: 15,
                fontSize: '12px',
                fontWeight: 400,
                textAlign: 'center',
            },
        },
        termsLink: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline !important'
            }
        },
        payments: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                margin: 5
            },
            '& img': {
                margin: 5,
                height: '3.5em'
            },
            '@media (max-width: 975px)': {
                marginTop: '15px'
            }
        }
    })
)

import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import Modal from "react-modal";

const SameZoneModal = ({ show, setShow }: any) => {
  const classes = useStyles();

  const modalStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "auto",
      width: "90%",
      maxWidth: "600px",
      maxHeight: "90vh",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, .3) 0px 5px 15px",
      border: "none",
      borderRadius: "20px",
      overflowY: "hidden",
      padding: 0,
    },
  };
  return (
    <Modal isOpen={show.show} style={modalStyles}>
      <div className={classes.modalContainer}>
        <div className={classes.content}>
          <h2>Overlapping Bundle Coverage!</h2>
          <p>
            Hey there! It looks like you already have an active bundle covering
            the same zone as this new plan.
          </p>
          <p>
            If you continue, when connecting in a covered country both plans
            will be activated & consumed in order of purchase. Any data
            remaining after a bundle has expired will be lost.
          </p>
          <p>Would you like to continue purchasing this plan?</p>
          <div className={classes.buttons}>
            <button
              className={classes.closeButton}
              onClick={() => {
                setShow(false);
              }}
              style={{ maxWidth: 300 }}
            >
              Cancel
            </button>
            <button
              className={classes.button}
              onClick={() => {
                if (show.continue) {
                  show.continue();
                }
              }}
              style={{ maxWidth: 300 }}
            >
              I understand, Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SameZoneModal;

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { theme } from "./styles/theme.tsx";
import { ThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConsentForm />
        <App />
      </ThemeProvider>
    </Provider>
  );
};

const Tags = ({ consented }) => {
  return (
    <>
      {consented ? (
        <>
          <Helmet>
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MZH84R3');`}</script>
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer1','GTM-W3PQMJK');`}</script>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=AW-10800792078"
            ></script>
            <script>
              {`window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', 'AW-10800792078');`}
            </script>
            <script async>
              {`!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('ts', '57qRm_KDmqlIBqGqSGhpOA');`}
            </script>
            <script>
              {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '513051200347111');
  fbq('track', 'PageView');`}
            </script>
            <script>{`!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('CDKH1N3C77U29KPVTUH0'); ttq.page(); }(window, document, 'ttq');`}</script>
          </Helmet>

          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-MZH84R3"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>
          </noscript>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-W3PQMJK"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>
          </noscript>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=AW-10800792078"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>
          </noscript>
          <noscript>
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=620226169079818&ev=PageView&noscript=1"
            />
          </noscript>
          <noscript>
            <img
              height="1"
              width="1"
              style="display:none;"
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=4210945&fmt=gif"
            />
          </noscript>
          <noscript>
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=513051200347111&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const ConsentForm = () => {
  const [consented, setConsented] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasConsented = localStorage.getItem("cookieConsent");
    if (hasConsented === null) {
      setShowModal(true);
    } else if (hasConsented === "true") {
      setConsented(true);
    }
  }, []);

  const consent = () => {
    setConsented(true);
    localStorage.setItem("cookieConsent", "true");
  };

  const removeConsent = () => {
    let reload = consented ? true : false;
    setConsented(false);
    localStorage.setItem("cookieConsent", "false");
    if (reload) {
      window.location.reload();
    }
  };

  const buttonStyle = {
    margin: "0 10px",
    backgroundColor: "#20ce88",
    color: "#fff",
    border: "none",
    fontFamily: "Urbane, sans-serif",
    minHeight: 40,
    width: 200,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: 500,
    padding: "5px",
  };

  const [bottom, setBottom] = useState(0);

  const hide = () => {
    setBottom(-1000);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };

  const show = () => {
    setBottom(-1000);
    setShowModal(true);
    setTimeout(() => {
      setBottom(0);
    }, 200);
  };

  return (
    <>
      <div
        onClick={show}
        style={{
          position: "fixed",
          bottom: 7,
          left: 7,
          height: 60,
          width: 60,
          borderRadius: "50%",
          backgroundColor: "#175458",
          zIndex: 10000,
          color: "#20ce88",
          cursor: "pointer",
          display: "flex",
          opacity: showModal ? 0 : 1,
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Termina, sans-serif",
          fontWeight: 600,
          fontSize: 20,
          boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
          transition: "all 0.4s ease",
        }}
      >
        <img
          src="/cookie-bite.svg"
          alt="cookie"
          style={{ width: 40, height: 40 }}
        />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: bottom,
          width: "100vw",
          backgroundColor: "#fff",
          zIndex: 100005,
          boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
          padding: "25px 30px",
          textAlign: "center",
          display: showModal ? "block" : "none",
          transition: "all 0.8s ease",
        }}
      >
        <h4
          style={{ margin: 0, color: "#000", fontFamily: "Urbane, sans-serif" }}
        >
          Cookie consent
        </h4>
        <p style={{ fontSize: 14 }}>
          This website uses necessary cookies that make the site work. We'd also
          like to set additional cookies to understand site usage and remember
          your settings.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            style={buttonStyle}
            onClick={() => {
              hide();
              removeConsent();
            }}
          >
            Reject Cookies
          </button>
          <button
            style={buttonStyle}
            onClick={() => {
              hide();
              consent();
            }}
            id="accept-cookies"
          >
            Accept Cookies
          </button>
        </div>
      </div>
      <Tags consented={consented} />
    </>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));

import { ThemeProvider } from "@material-ui/core/styles";
import Head from "next/head";
import { theme } from "../../styles/theme";
import React from "react";
import { useStyles } from "./style";
import Header from "../Header";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import Preloader from "../Preloader/index";
import { useLocation, useNavigate } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import ReleaseImsiModal from "../ReleaseImsiModal";

interface IMainLayoutProps {
  title?: string;
  description?: string;
  account?: boolean;
  children: React.ReactNode;
}

const MainLayout: React.FC<IMainLayoutProps> = ({
  title,
  description,
  account,
  children,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [transparent, setTransparent] = useState(false);

  useEffect(() => {
    if (!account) {
      document.addEventListener("scroll", setTransparency, true);

      if (window.pageYOffset > 5) {
        setTransparent(false);
      } else if (window.pageYOffset < 5) {
        setTransparent(true);
      }
    } else {
      setTransparent(false);
    }

    return () => {
      document.removeEventListener("scroll", setTransparency, true);
    };
  }, []);

  const setTransparency = () => {
    if (window.pageYOffset > 5) {
      setTransparent(false);
    } else if (window.pageYOffset < 5) {
      setTransparent(true);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showReleaseImsi, setShowReleaseImsi] = useState(false);

  return (
    <>
      <MetaTags>
        <title>{title}BreatheSIM</title>
        <meta
          name="description"
          content={
            description
              ? description
              : "BreatheSIM offers flexible, low-cost eSIM data bundles to keep you connected on your travels. Data plans start from just $1 for 1GB of data."
          }
        />
      </MetaTags>
      <Header transparent={transparent} />
      {account ? (
        <div className={classes.navbar}>
          <ReleaseImsiModal
            show={showReleaseImsi}
            setShow={setShowReleaseImsi}
          />
          <div className={classes.buttons}>
            <button
              className={
                location.pathname.includes("/account/bundles")
                  ? classes.navBtnActive
                  : classes.navBtn
              }
              onClick={() => {
                navigate("/account/bundles");
              }}
              style={{ position: "relative" }}
            >
              <span>My Bundles</span>
            </button>
            <button
              className={
                location.pathname.includes("/account/payment")
                  ? classes.navBtnActive
                  : classes.navBtn
              }
              onClick={() => {
                navigate("/account/payment");
              }}
            >
              <span>Payment</span>
            </button>
            <button
              className={
                location.pathname.includes("/account/support")
                  ? classes.navBtnActive
                  : classes.navBtn
              }
              onClick={() => {
                navigate("/account/support");
              }}
            >
              <span>Support</span>
            </button>
            <button
              className={classes.navBtn}
              onClick={(e: any) => {
                handleOpenMenu(e);
              }}
              style={{ width: "auto", padding: "0 30px" }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_16613_27334)">
                  <path
                    d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.192 19.3228C12.0664 19.3228 11.9398 19.3228 11.817 19.3228L8.81234 21C7.64265 20.6065 6.55772 19.9959 5.61453 19.2L5.60328 15.825C5.53672 15.72 5.4739 15.6141 5.41578 15.5053L2.42797 13.8038C2.19263 12.6134 2.19263 11.3885 2.42797 10.1981L5.41297 8.50125C5.4739 8.39344 5.53672 8.28656 5.60047 8.18156L5.61547 4.80656C6.55781 4.00842 7.64245 3.39548 8.81234 3L11.8123 4.67719C11.938 4.67719 12.0645 4.67719 12.1873 4.67719L15.1873 3C16.357 3.39346 17.442 4.00414 18.3852 4.8L18.3964 8.175C18.463 8.28 18.5258 8.38594 18.5839 8.49469L21.5698 10.1953C21.8052 11.3857 21.8052 12.6106 21.5698 13.8009L18.5848 15.4978C18.5239 15.6056 18.4611 15.7125 18.3973 15.8175L18.3823 19.1925C17.4406 19.9908 16.3566 20.604 15.1873 21L12.192 19.3228Z"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_16613_27334">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={menuOpen}
              onClose={handleCloseMenu}
              disableScrollLock
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Link to="/account/referral">
                <MenuItem
                  className={classes.menuItem}
                  style={{ color: "#175458", marginBottom: 12 }}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1667 7.08716H1.83333C1.3731 7.08716 1 7.47647 1 7.95671V11.4349C1 11.9152 1.3731 12.3045 1.83333 12.3045H20.1667C20.6269 12.3045 21 11.9152 21 11.4349V7.95671C21 7.47647 20.6269 7.08716 20.1667 7.08716Z"
                      stroke="#175458"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.3337 12.3044V20.1304C19.3337 20.3611 19.2459 20.5822 19.0896 20.7453C18.9333 20.9084 18.7213 21 18.5003 21H3.50033C3.27931 21 3.06735 20.9084 2.91107 20.7453C2.75479 20.5822 2.66699 20.3611 2.66699 20.1304V12.3044"
                      stroke="#175458"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 7.08716V21.0001"
                      stroke="#175458"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0823 1.78398C17.0552 2.79919 17.1125 4.50787 16.0823 5.46003C14.3208 7.08719 11 7.08719 11 7.08719C11 7.08719 11 3.62201 12.5625 1.78398C13.4719 0.708995 15.1094 0.768777 16.0823 1.78398Z"
                      stroke="#175458"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.91832 1.78398C4.9454 2.79919 4.88811 4.50787 5.91832 5.46003C7.67978 7.08719 11.0006 7.08719 11.0006 7.08719C11.0006 7.08719 11.0006 3.62201 9.43811 1.78398C8.52873 0.708995 6.89123 0.768777 5.91832 1.78398Z"
                      stroke="#175458"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ marginLeft: 8 }}>Referral Code</span>
                </MenuItem>
              </Link>
              <MenuItem
                className={classes.menuItem}
                style={{ color: "#175458" }}
                onClick={() => {
                  setShowReleaseImsi(true);
                }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.2728 10.0907L19.6683 8.7589C20.4963 7.90162 20.9544 6.75343 20.9441 5.56162C20.9337 4.36982 20.4557 3.22976 19.6129 2.387C18.7701 1.54423 17.6301 1.06619 16.4383 1.05584C15.2465 1.04548 14.0983 1.50364 13.241 2.33163L11.9092 3.72708"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.72757 11.9092L2.33212 13.241C1.50412 14.0983 1.04597 15.2465 1.05632 16.4383C1.06668 17.6301 1.54472 18.7701 2.38749 19.6129C3.23025 20.4557 4.37031 20.9337 5.56211 20.9441C6.75391 20.9544 7.90211 20.4963 8.75939 19.6683L10.0912 18.2728"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2729 14.6365H21.0002"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 7.36353H3.72727"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.6362 18.2727V21"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.36377 1V3.72727"
                    stroke="#175458"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ marginLeft: 8 }}>Release Your eSIM</span>
              </MenuItem>
            </Menu>
          </div>
        </div>
      ) : (
        ""
      )}
      <main className={classes.main}>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;

import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    modalContainer: {
      width: "100%",
      height: "100%",
      overflowY: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "50px 24px",
      "& h2": {
        color: "#175458",
        fontFamily: "Termina, Roboto, sans-serif",
        fontSize: 28,
        fontWeight: 600,
        "@media (max-width: 930px)": {
          fontSize: 20,
        },
        "@media (max-width: 400px)": {
          fontSize: 18,
        },
      },
    },
    imageContainer: {
      width: "100%",
      textAlign: "center",
      marginBottom: "30px",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    content: {
      color: "#175458",
      "& p": {
        fontSize: 16,
        lineHeight: "24px",
      },
    },
    buttons: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: "30px",
    },
    button: {
      background: "#20ce88",
      border: "none",
      color: "#175458",
      borderRadius: "60px",
      fontSize: "1rem",
      fontWeight: 500,
      height: 50,
      width: "100%",
      maxWidth: 500,
      transition: "all 0.3s ease",
      fontFamily: "Termina, sans-serif",
      cursor: "pointer",
      "&:hover": {
        background: "#1aa16b",
      },
      "&:disabled": {
        opacity: "0.7",
        cursor: "auto",
        "&:hover": {
          background: "#20ce88",
        },
      },
    },
    cancelButton: {
      background: "none",
      border: "2px solid #175458",
      color: "#175458",
      borderRadius: "60px",
      fontSize: "1rem",
      fontWeight: 500,
      height: 50,
      maxWidth: 500,
      transition: "all 0.3s ease",
      fontFamily: "Termina, sans-serif",
      cursor: "pointer",
      marginRight: 12,
      padding: "0px 32px",
      "&:hover": {
        background: "rgba(0,0,0,0.05)",
      },
      "&:disabled": {
        opacity: "0.7",
        cursor: "auto",
        "&:hover": {
          background: "none",
        },
      },
    },
  })
);

import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import Modal from "react-modal";
import { ApiPostAuth } from "../../pages/api/api";
import { CircularProgress } from "@material-ui/core";

const ReleaseImsiModal = ({ show, setShow }: any) => {
  const classes = useStyles();

  const modalStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "auto",
      width: "90%",
      maxWidth: "600px",
      maxHeight: "90vh",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, .3) 0px 5px 15px",
      border: "none",
      borderRadius: "20px",
      overflowY: "hidden",
      padding: 0,
    },
  };

  const [section, setSection] = useState("confirm");
  const [loading, setLoading] = useState(false);

  const handleReleaseImsi = () => {
    setLoading(true);
    setSection("confirm");
    ApiPostAuth("/users/imsi/detach", {
      reason: "web",
    })
      .then((response) => {
        setLoading(false);
        setSection("success");
      })
      .catch((error) => {
        setLoading(false);
        setSection("failure");
      });
  };

  return (
    <Modal isOpen={show} style={modalStyles}>
      <div className={classes.modalContainer}>
        <div className={classes.imageContainer}>
          {section === "confirm" ? (
            <img src="/succes_is.svg" />
          ) : section === "success" ? (
            <img src="/succes_is (1).svg" />
          ) : section === "failure" ? (
            <img src="/fail_is.svg" />
          ) : (
            ""
          )}
        </div>
        <div className={classes.content}>
          <h2>
            {section === "confirm"
              ? "Are you sure you want to release your eSIM?"
              : section === "success"
              ? "eSIM Removed!"
              : section === "failure"
              ? "eSIM Removal Failed"
              : ""}
          </h2>
          <p>
            {section === "confirm"
              ? "You are about to remove your eSIM from your account. Please note that any existing plans associated with this eSIM will not be transferred. If you need a refund, please contact our support team."
              : section === "success"
              ? "Your eSIM has been successfully removed from your account. You can now proceed to purchase a new eSIM and data plan."
              : section === "failure"
              ? "An error occurred while trying to remove your eSIM. Please try again later or contact our support team for assistance."
              : ""}
          </p>
          <div className={classes.buttons}>
            <button
              className={classes.cancelButton}
              onClick={() => {
                setShow(false);
                setTimeout(() => {
                  setSection("confirm");
                }, 300);
              }}
              style={{ maxWidth: 300 }}
              disabled={loading}
            >
              {section === "confirm"
                ? "Cancel"
                : section === "success"
                ? "Close"
                : section === "failure"
                ? "Cancel"
                : ""}
            </button>
            <button
              className={classes.button}
              onClick={() => {
                if (section === "confirm" || section === "failure") {
                  handleReleaseImsi();
                } else if (section === "success") {
                  setShow(false);
                  setTimeout(() => {
                    setSection("confirm");
                  }, 300);
                }
              }}
              style={{ maxWidth: 300 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#175458",
                    width: 20,
                    height: 20,
                  }}
                />
              ) : section === "confirm" ? (
                "Remove eSIM"
              ) : section === "success" ? (
                "Shop Now"
              ) : section === "failure" ? (
                "Try Again"
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReleaseImsiModal;

import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    main: {
      minHeight: "100vh",
      overflowY: "hidden",
    },
    green: {
      color: "#175458 !important",
    },

    //////////////////////////////////////////////////////////////////////
    /******************         TITLE SECTION        ********************/

    titleSection: {
      backgroundColor: "#20ce88",
      position: "relative",
      //minHeight: "100vh",
      paddingTop: "100px",
      paddingBottom: "400px",
      "& svg": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "auto",
        zIndex: 0,
      },
      "@media (max-width: 960px)": {
        paddingTop: "80px",
      },
      "@media (max-width: 450px)": {
        paddingTop: "60px",
      },
    },
    titleContainer: {
      height: "100%",
      alignItems: "flex-start",
    },
    phone: {
      width: "320px",
      height: "auto",
      position: "absolute",
      top: 30,
      right: 60,
      zIndex: 3,
      "@media (max-width: 960px)": {
        display: "none",
      },
    },
    phoneCarousel: {
      width: "270px",
      height: "563.58px",
      borderRadius: "30px",
      //background: 'linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(189,189,189,1) 95%)',
      position: "relative",
      top: 25,
      right: 86,
      zIndex: 2,
      "& img": {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        transition: "all 1s ease",
      },
    },
    phoneActive: {
      opacity: 1,
    },
    phoneInactive: {
      opacity: 0,
    },
    smallPhoneContainer: {
      position: "relative",
      display: "none",
      "@media (max-width: 960px)": {
        display: "block",
      },
    },
    smallPhoneCarousel: {
      width: "170px",
      height: "356.03px",
      borderRadius: "15px",
      marginBottom: "60px",
      //background: 'linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(189,189,189,1) 95%)',
      position: "relative",
      zIndex: 1,
      top: 15,
      "& img": {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        transition: "all 1s ease",
      },
    },
    smallPhone: {
      zIndex: 2,
      width: "200px",
      height: "auto",
      display: "none",
      left: -15,
      position: "absolute",
      "@media (max-width: 960px)": {
        display: "block",
      },
    },
    phoneContainer: {
      "@media (max-width: 960px)": {
        display: "none!important",
      },
    },
    phoneAnimationContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      position: "relative",
      paddingTop: 30,
    },
    titleText: {
      position: "relative",
      zIndex: 1,
      height: "70%",
      padding: "0 15px",
      paddingTop: "150px",
      "& .badge": {
        padding: 0,
        margin: 0,
        background: "none",
        border: "none",
      },
      "& input": {
        borderRadius: "10px",
        padding: "15px",
        fontFamily: "Urbane, Roboto, sans-serif",
        fontSize: "1rem",
        border: "1px solid transparent",
        transition: "all 0.15s ease",
        color: "#8c8c8c",
        "&:focus": {
          outline: "none",
          boxShadow: "inset 0 0 5px rgba(0,0,0,0.6)",
          border: "1px solid rgba(0,0,0,0.5)",
        },
        "&::placeholder": {
          color: "#bdbdbd",
        },
        "&:read-only": {
          backgroundColor: "#e8e8e8",
          color: "#bdbdbd",
        },
        "@media (max-width: 960px)": {
          padding: "10px",
          fontSize: "14px",
        },
      },
      "@media (max-width: 960px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: "30px",
      },
    },
    seeHowItWorks: {
      background: "#20ce88",
      border: "none",
      borderRadius: "500px",
      display: "block",
      color: "#175458",
      fontFamily: "Termina, Roboto, sans-serif",
      fontSize: "16px",
      padding: "15px 30px",
      fontWeight: 500,
      cursor: "pointer",
      transition: "background 0.3s ease, color 0.3s ease",
      marginTop: 100,
      "&:hover": {
        background: "#175458",
        color: "#20ce88",
      },
      "@media (max-width: 960px)": {
        marginTop: 30,
      },
    },
    tellMeMore: {
      background: "#20ce88",
      border: "none",
      borderRadius: "500px",
      display: "flex",
      alignItems: "center",
      color: "#175458",
      fontFamily: "Termina, Roboto, sans-serif",
      fontSize: "16px",
      padding: "15px 30px",
      fontWeight: 500,
      cursor: "pointer",
      transition: "all 0.3s ease",
      marginTop: 10,
      "&:hover": {
        background: "#175458",
        color: "#20ce88",
      },
      "&:disabled": {
        cursor: "auto",
        background: "#e8e8e8",
        color: "#bdbdbd",
        "&:hover": {
          background: "#e8e8e8",
          color: "#bdbdbd",
        },
      },
    },
    thankYouTitle: {
      color: "#175458",
      padding: "20px 20px 0 20px",
      fontFamily: "Termina",
      borderRadius: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& svg": {
        width: "50px",
        height: "50px",
        marginBottom: "15px",
      },
      "& h1": {
        fontWeight: 400,
        margin: "0",
        textAlign: "center",
      },
    },
    thankYouText: {
      padding: "20px 20px 40px 20px",
      textAlign: "center",
      color: "#175458",
      lineHeight: "22px",
    },
    modalClose: {
      backgroundColor: "#175458",
      color: "#fff",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 9999,
      cursor: "pointer",
      right: -15,
      top: -15,
      transition: "all 0.3s ease",
      "&:hover": {
        color: "#20ce88",
      },
      "& svg": {
        width: "35px",
        height: "35px",
        strokeWidth: "1px",
      },
    },
    heading: {
      fontFamily: "Termina, Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "52px",
      margin: 0,
      padding: 0,
      color: "#fff",
      zIndex: 9999,
      "@media (max-width: 1100px)": {
        fontSize: "45px",
      },
      "@media (max-width: 960px)": {
        fontSize: "35px",
        textAlign: "center",
      },
      "@media (max-width: 450px)": {
        fontSize: "25px",
      },
    },
    tagLine: {
      color: "#fff",
      fontWeight: 400,
      fontSize: "20px",
      width: "65%",
      letterSpacing: "0.5px",
      "@media (max-width: 960px)": {
        textAlign: "center",
        fontSize: "16px",
        maxWidth: "390px",
      },
      "@media (max-width: 450px)": {
        fontSize: "12px",
        maxWidth: "100%",
        width: "100%",
      },
    },
    nameContainer: {
      maxWidth: "600px",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
      marginTop: "40px",
      "@media (max-width: 960px)": {
        flexDirection: "column",
        maxWidth: "500px",
        width: "80%",
        marginBottom: "15px",
        marginTop: "15px",
      },
    },
    emailContainer: {
      maxWidth: "600px",
      "@media (max-width: 960px)": {
        maxWidth: "500px",
        width: "80%",
      },
    },
    firstName: {
      width: "49%",
      "@media (max-width: 960px)": {
        width: "100%",
        marginBottom: "15px",
      },
    },
    lastName: {
      width: "49%",
      "@media (max-width: 960px)": {
        width: "100%",
      },
    },
    email: {
      width: "100%",
    },
    checkboxContainer: {
      maxWidth: "600px",
      display: "flex",
      color: "#fff",
      alignItems: "center",
      "@media (max-width: 960px)": {
        maxWidth: "500px",
        width: "80%",
      },
    },
    checkbox: {
      width: "25px",
      height: "25px",
      color: "#fff",
      marginRight: "10px",
      padding: 0,
    },
    checked: {
      color: "#20ce88!important",
    },
    checkboxText: {
      fontSize: "14px",
      lineHeight: "20px",
      paddingTop: "15px",
      letterSpacing: "0.3px",
      "@media (max-width: 450px)": {
        fontSize: "12px",
      },
    },
    appContainer: {
      display: "flex",
      marginTop: 50,
      "@media (max-width: 960px)": {
        marginTop: 0,
      },
    },
    appStore: {
      cursor: "pointer",
      background: "none",
      border: "none",
      padding: 0,
      "& img": {
        height: "54.4px",
        margin: "12.8px 5px",
        "@media (max-width: 450px)": {
          height: "40.8px",
          margin: "9.6px 5px",
        },
      },
    },
    googlePlay: {
      cursor: "pointer",
      background: "none",
      border: "none",
      padding: 0,
      "& img": {
        height: "80px",
        "@media (max-width: 450px)": {
          height: "60px",
        },
      },
    },

    //////////////////////////////////////////////////////////////////////
    /******************         STEPS SECTION        ********************/

    stepsSection: {
      backgroundColor: "#20ce88",
      position: "relative",
      paddingBottom: "40px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "@media (max-width: 750px)": {
        padding: "0 15px 40px 15px",
      },
      "& svg": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "auto",
      },
    },
    stepsHeading: {
      color: "#175458",
      fontFamily: "Termina, Roboto, sans-serif",
      fontWeight: 400,
      width: "100%",
      textAlign: "center",
      margin: 0,
      padding: "20px 0",
      fontSize: "36px",
      lineHeight: "150%",
      "@media (max-width: 450px)": {
        fontSize: "25px",
      },
    },
    stepsTag: {
      color: "#fff",
      fontWeight: 400,
      fontSize: "21px",
      width: "100%",
      textAlign: "center",
      margin: 0,
      padding: 0,
      "@media(max-width: 600px)": {
        fontSize: "16px",
      },
    },
    imgContainer: {
      display: "flex",
      width: "100%",
      padding: "50px 90px",
      justifyContent: "space-around",
      position: "relative",
      zIndex: 1,
      maxWidth: 2000,
      "& img": {
        height: 133,
        width: "auto",
      },
      "@media (max-width: 1250px)": {
        display: "none",
      },
    },
    carousel: {
      display: "none",
      margin: "60px auto",
      marginBottom: "30px",
      paddingBottom: "50px",
      justifyContent: "center",
      width: "500px",
      height: "300px",
      "@media (max-width: 1250px)": {
        display: "flex",
      },
      "@media (max-width: 750px)": {
        width: "100%",
      },
      "& .CarouselItem": {
        margin: "0 50%",
      },
    },
    indicators: {
      display: "block",
    },
    number: {
      background: "#175458",
      color: "#20ce88",
      fontFamily: "Termina, Roboto, sans-serif",
      width: "35px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50px",
      marginRight: "15px",
      fontWeight: 500,
    },
    step: {
      color: "#fff",
      margin: 0,
      padding: 0,
    },
    stepContainer: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
    },

    //////////////////////////////////////////////////////////////////////
    /******************         BUNDLES SECTION        ********************/

    bundleSection: {
      background: "#fff",
      padding: "100px 50px 20px 50px",
      "@media (max-width: 1050px)": {
        padding: "100px 15px 20px 15px",
      },
    },
    searchBar: {
      display: "flex",
      alignItems: "center",
      width: "500px",
      margin: "30px auto",
      "& svg": {
        position: "absolute",
        margin: "0 25px",
        fill: "#757575",
        "@media (max-width: 650px)": {
          margin: "0 20px 0 10px",
        },
      },
      "@media (max-width: 650px)": {
        width: "90%",
      },
    },
    searchInput: {
      width: "100%",
      padding: "15px 60px",
      borderRadius: "10px",
      background: "#ededed",
      border: "2px solid transparent",
      fontFamily: "Urbane, Roboto, sans-serif",
      fontSize: "14px",
      color: "#757575",
      fontWeight: 400,
      "&:focus": {
        outline: "none!important",
        border: "2px solid #bdbdbd",
      },
      "@media (max-width: 650px)": {
        padding: "15px 15px 15px 40px",
      },
      "@media (max-width: 510px)": {
        fontSize: 10,
      },
    },
    bundleHeading: {
      fontFamily: "Termina, Roboto, sans-serif",
      color: "#175458",
      fontWeight: 400,
      width: "100%",
      textAlign: "center",
      fontSize: "38px",
      padding: 0,
      margin: 0,
      "@media (max-width: 450px)": {
        fontSize: "25px",
      },
    },
    countryZoneBtn: {
      width: "260px",
      margin: "0 10px",
      border: "none",
      background: "#ededed",
      borderRadius: "500px",
      color: "#757575",
      padding: "15px 0",
      fontFamily: "Termina, Roboto, sans-serif",
      fontSize: "18px",
      position: "relative",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#175458",
      },
      "@media (max-width: 575px)": {
        width: "48%",
      },
      "@media (max-width: 500px)": {
        padding: "10px 0",
        fontSize: 14,
      },
      "@media (max-width: 420px)": {
        margin: "0 5px",
      },
    },
    countryZoneBtnActive: {
      width: "260px",
      margin: "0 10px",
      border: "none",
      background: "#175458",
      borderRadius: "500px",
      color: "#fff",
      padding: "15px 0",
      fontFamily: "Termina, Roboto, sans-serif",
      fontSize: "18px",
      position: "relative",
      transition: "all 0.3s ease",
      "&:after": {
        content: '""',
        borderStyle: "solid",
        position: "absolute",
        left: "50%",
        bottom: "-10px",
        marginLeft: "-10px",
        borderWidth: "10px 12.5px 0 12.5px",
        borderColor: "#175458 transparent transparent transparent",
      },
      "@media (max-width: 575px)": {
        width: "48%",
      },
      "@media (max-width: 500px)": {
        padding: "10px 0",
        fontSize: 14,
      },
      "@media (max-width: 420px)": {
        margin: "0 5px",
      },
    },
    popularHeading: {
      width: "100%",
      textAlign: "center",
      color: "#175458",
      fontSize: "18px",
      fontWeight: 400,
      margin: "50px 0 20px 0",
      lineHeight: "150%",
    },
    currencyHeading: {
      textAlign: "center",
      color: "#175458",
      fontSize: "16px",
      fontWeight: 500,
      margin: "20px 0",
      marginRight: 15,
    },
    flag: {
      width: "38px",
      height: "27.14px",
      margin: "auto 0",
      borderRadius: 3,
    },
    zoneFlag: {
      height: "27px",
      width: "27px",
      margin: "auto 0",
      borderRadius: 3,
    },
    countryName: {
      fontWeight: 600,
      fontSize: "16px",
      color: "#175458",
      marginLeft: "30px",
    },
    accordionContainer: {
      width: "90%",
      margin: "0 auto",
      boxShadow: "none",
      borderRadius: 0,
      maxWidth: 1300,
      "& .Mui-expanded": {
        "& #expand": {
          display: "none",
        },
        "& #collapse": {
          display: "flex",
        },
      },
      "@media (max-width: 750px)": {
        width: "100%",
      },
    },
    accordion: {
      boxShadow: "none !important",
      border: "none !important",
      borderTop: "2px solid #d4d4d4 !important",
      borderRadius: "0 !important",
      "&:last-of-type": {
        borderBottom: "2px solid #d4d4d4 !important",
      },
    },
    accordionSummary: {
      margin: "0 !important",
      transition: "all 0.3s ease !important",
      "&:hover": {
        backgroundColor: "rgba(32, 206, 136, 0.1) !important",
      },
      "& .MuiAccordionSummary-content": {
        margin: "5px 0 !important",
      },
    },
    expandIcon: {
      width: "25px",
      height: "25px",
      fontSize: "23px",
      lineHeight: "23px",
      background: "#175458",
      color: "#fff",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        height: "100%",
      },
    },
    collapseIcon: {
      display: "none",
      width: "25px",
      height: "25px",
      fontSize: "36px",
      lineHeight: "17px",
      background: "#20ce88",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        height: "100%",
      },
    },
    badge: {
      marginLeft: "auto",
      backgroundColor: "#20ce88",
      color: "#fff",
      fontFamily: "Termina, Roboto, sans-serif",
      borderRadius: 100,
      textTransform: "uppercase",
      padding: "5px 8px",
      fontSize: 12,
      marginBottom: 25,
    },
    bundle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "center",
      backgroundColor: "#f2f2f2",
      padding: 30,
      borderRadius: 20,
      maxWidth: 350,
      width: "100%",
      margin: "20px 0",
      transition: "all 0.3s ease",
    },
    selectBtn: {
      background: "#20ce88",
      border: "none",
      borderRadius: "500px",
      display: "block",
      color: "#175458",
      fontFamily: "Termina, Roboto, sans-serif",
      fontSize: "16px",
      padding: "15px 60px",
      fontWeight: 500,
      cursor: "pointer",
      transition: "background 0.3s ease, color 0.3s ease",
      margin: "0 auto",
      "@media (max-width: 400px)": {
        padding: "15px 0",
        width: "100%",
        marginRight: 10,
      },
      "&:hover": {
        background: "#175458",
        color: "#20ce88",
      },
    },
    bundleExpandBtn: {
      width: 49.2,
      height: 49.2,
      borderRadius: "100%",
      border: "2px solid #175458",
      background: "none",
      color: "#175458",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "& svg": {
        width: 30,
        height: 30,
      },
      "&:hover": {
        background: "#175458",
        color: "#fff",
      },
    },
    bundlesContainer: {
      display: "grid",
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "flex-start",
      gridTemplateColumns: "1fr 1fr 1fr",
      columnGap: 20,
      width: "100%",
      "@media (max-width: 1355px)": {
        gridTemplateColumns: "1fr 1fr",
      },
      "@media (max-width: 870px)": {
        gridTemplateColumns: "1fr",
      },
    },
    largeBundlesContainer: {
      display: "grid",
      flexWrap: "wrap",
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "start",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: 20,
      gridRowGap: 20,
      width: "100%",
      maxWidth: 900,
      margin: "0 auto 24px auto",
      "& .bundle": {
        margin: "0px",
        maxWidth: "100%",
      },
      "@media (max-width: 670px)": {
        gridTemplateColumns: "1fr",
        "& .bundle": {
          maxWidth: 450,
        },
      },
      /*"@media (max-width: 1390px)": {
        gridTemplateColumns: "1fr 1fr 1fr",
      },
      "@media (max-width: 1000px)": {
        gridTemplateColumns: "1fr 1fr",
      },
      "@media (max-width: 675px)": {
        gridTemplateColumns: "1fr",
      },*/
    },
    bundleInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      color: "#175458",
      "& h4": {
        fontFamily: "Termina, Roboto, sans-serif",
        fontWeight: 500,
        margin: 0,
        marginBottom: 5,
        fontSize: 18,
      },
      "& span": {
        fontWeight: 600,
        fontSize: 14,
      },
    },
    activationInfo: {
      "& h5": {
        color: "#175458",
        fontSize: "16px",
        margin: 0,
        padding: 0,
        fontWeight: 600,
      },
      "& p": {
        marginTop: 8,
        marginBottom: 20,
        color: "#175458",
        lineHeight: "23px",
      },
    },
    autorenewInfo: {
      background: "#175458",
      borderRadius: 20,
      color: "#fff",
      width: "100%",
      padding: 20,
      marginBottom: 30,
      "& h5": {
        fontSize: "16px",
        margin: 0,
        padding: 0,
        fontWeight: 600,
      },
    },
    viewCountries: {
      color: "#175458",
      fontSize: "16px",
      margin: "20px 0",
      padding: 0,
      fontWeight: 600,
      border: "none",
      background: "none",
      display: "flex",
      alignItems: "center",
      "& button": {
        width: 40,
        height: 40,
        marginLeft: 5,
      },
    },
    countryList: {
      margin: "10px 15px",
      display: "flex",
      alignItems: "center",
      color: "#175458",
      "& img": {
        height: "18px",
        width: "25.2px",
        marginRight: 5,
      },
    },
    countriesModalClose: {
      backgroundColor: "#fff",
      color: "#d4d4d4",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 9999,
      cursor: "pointer",
      right: 10,
      top: 10,
      transition: "all 0.3s ease",
      "&:hover": {
        color: "#175458",
      },
      "& svg": {
        width: "35px",
        height: "35px",
        strokeWidth: "1px",
      },
    },

    //////////////////////////////////////////////////////////////////////
    /******************         DISCOVER SECTION        *****************/

    discoverSection: {
      background: "#f2f2f2",
      borderRadius: "80px 80px 0 0",
      padding: "50px",
      marginTop: 50,
      "@media (max-width: 1050px)": {
        padding: "50px 15px",
        borderRadius: "30px 30px 0 0",
      },
    },
    discoverHeading: {
      fontFamily: "Termina, Roboto, sans-serif",
      color: "#175458",
      fontWeight: 400,
      width: "100%",
      textAlign: "center",
      fontSize: "38px",
      padding: 0,
      margin: 0,
    },
    discoverTag: {
      color: "#20ce88",
      fontWeight: 400,
      width: "100%",
      textAlign: "center",
      fontSize: "20px",
      padding: 0,
      margin: "20px 0 60px 0",
      "@media (max-width: 900px)": {
        fontSize: 16,
      },
    },
    blogContainer: {
      display: "grid",
      maxWidth: 1300,
      gridTemplateColumns: "1fr 1fr 1fr",
      columnGap: 20,
      alignItems: "stretch",
      flexWrap: "wrap",
      justifyContent: "space-around",
      justifyItems: "center",
      width: "100%",
      margin: "0 auto",
      "@media (max-width: 1200px)": {
        gridTemplateColumns: "1fr 1fr",
      },
      "@media (max-width: 800px)": {
        gridTemplateColumns: "1fr",
      },
    },
    blogPost: {
      width: "100%",
      height: "100%",
      maxWidth: 430,
      borderRadius: 20,
      paddingBottom: 50,
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        "& img, div": {
          boxShadow: "rgba(23, 84, 88, 0.3) 0px 3px 8px",
        },
      },
      "& img": {
        transition: "all 0.3s ease",
        height: "auto",
        width: "100%",
        margin: 0,
        borderRadius: "20px 20px 0 0",
      },
      "@media (max-width: 750px)": {
        margin: 0,
      },
    },
    blogTitle: {
      transition: "all 0.3s ease",
      width: "100%",
      flexGrow: 1,
      background: "#20ce88",
      color: "#fff",
      padding: "25px 30px",
      fontFamily: "Termina, Roboto, sans-serif",
      fontSize: 18,
      lineHeight: "24px",
      borderRadius: "0 0 20px 20px",
    },
    videoSection: {
      background: "#f2f2f2",
      padding: "100px 50px",
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "2fr 3fr",
      gridColumnGap: 24,
      "@media(max-width: 1150px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "@media(max-width: 768px)": {
        padding: "50px 24px",
      },
    },
    videoSectionLeft: {
      "@media(max-width: 1150px)": {
        textAlign: "center",
        marginBottom: 50,
      },
      "& h3": {
        color: "#175458",
        fontFamily: "Termina, Roboto, sans-serif",
        fontWeight: 400,
        width: "100%",
        margin: 0,
        fontSize: "36px",
        marginBottom: 18,
        lineHeight: "150%",
        "@media(max-width: 768px)": {
          fontSize: 28,
        },
        "@media(max-width: 450px)": {
          fontSize: 22,
        },
      },
      "& p": {
        color: "#20ce88",
        fontWeight: 600,
        fontSize: "21px",
        width: "100%",
        margin: 0,
        padding: 0,
        "@media(max-width: 600px)": {
          fontSize: "16px",
        },
      },
    },
    videoContainer: {},
    getAppSection: {
      padding: "200px 50px",
      backgroundImage: "url(/dylan-nolte-eL6K8uiRPsE-unsplash.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      "@media(max-width: 1000px)": {
        padding: "100px 24px",
      },
      "@media(max-width: 768px)": {
        padding: "50px 24px 100px 24px",
        display: "flex",
      },
      "& h2": {
        color: "#175458",
        fontFamily: "Termina, Roboto, sans-serif",
        fontWeight: 500,
        width: "100%",
        margin: 0,
        fontSize: "36px",
        marginBottom: 18,
        lineHeight: "150%",
        "@media(max-width: 768px)": {
          fontSize: 28,
        },
        "@media(max-width: 450px)": {
          fontSize: 22,
        },
      },
      "& p": {
        color: "#175458",
        fontWeight: 400,
        fontSize: "21px",
        width: "100%",
        margin: 0,
        padding: 0,
        "@media(max-width: 960px)": {
          marginBottom: 50,
        },
        "@media(max-width: 600px)": {
          fontSize: "16px",
        },
      },
    },
    contentContainer: {
      "@media(max-width: 768px)": {
        marginBottom: 300,
      },
    },
    headingPromo: {
      fontFamily: "Termina, Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "48px",
      margin: 0,
      padding: 0,
      color: "#fff",
      zIndex: 9999,
      "@media (max-width: 1100px)": {
        fontSize: "45px",
      },
      "@media (max-width: 960px)": {
        fontSize: "35px",
        textAlign: "center",
      },
      "@media (max-width: 450px)": {
        fontSize: "25px",
      },
    },
    tagLinePromo: {
      color: "#fff",
      fontWeight: 600,
      fontSize: "26px",
      lineHeight: "150%",
      width: "65%",
      letterSpacing: "0.5px",
      "@media (max-width: 960px)": {
        textAlign: "center",
        fontSize: "16px",
        maxWidth: "390px",
      },
      "@media (max-width: 450px)": {
        maxWidth: "100%",
        width: "100%",
      },
    },
    titleSectionFrance: {
      backgroundColor: "#20ce88",
      position: "relative",
      //minHeight: "100vh",
      paddingTop: "100px",
      paddingBottom: "300px",
      overflow: "hidden",
      borderRadius: "0px 0px 100px 100px",
      "& svg": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "auto",
        zIndex: 0,
      },
      "@media (max-width: 1100px)": {
        paddingBottom: "50px",
        borderRadius: "0px 0px 50px 50px",
      },
      "@media (max-width: 960px)": {
        paddingTop: "80px",
      },
      "@media (max-width: 450px)": {
        paddingTop: "60px",
      },
    },
    heroSwiper: {
      position: "absolute!important" as any,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: "0px 0px 100px 100px",
      animation: "$zoomEffect 14000ms 0ms infinite normal ease-out",
      "@media (max-width: 1100px)": {
        borderRadius: "0px 0px 50px 50px",
      },
    },
    heroSlide: {
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      "& img": {
        width: "100%",
      },
    },
    heroSlide1: {
      backgroundImage: "url(gentrit-sylejmani-JjUyjE-oEbM-unsplash.jpg)",
    },
    heroSlide2: {
      backgroundImage: "url(gonzalo-facello-RjCo6j0BkU8-unsplash.jpg)",
    },
    heroSlide3: {
      backgroundImage: "url(braden-collum-9HI8UJMSdZA-unsplash.jpg)",
    },
    heroSlide4: {
      backgroundImage: "url(austris-augusts-52p1K0d0euM-unsplash.jpg)",
    },
    "@keyframes zoomEffect": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.05)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    swiperContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    swiperOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: "rgba(0,0,0,0.3)",
      zIndex: 1,
    },
  })
);
